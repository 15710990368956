import {Button} from '@material-ui/core';
import React from 'react';
import BaseElem from '../BaseElem';
import '../Common.css';
import {UNDEF} from '../Constants';
import {blur, clamp} from '../Util/Util';
import './DialogTextDropdown.css';
import {InputField} from './InputField';
import {VList} from './VList';

type TextDropdownProps = {
    items: any[];
    selectedItem?: any;
    highlightedItem?: string;

    width?: string; // when specified disable dynamic width
    maxWidth?: string;
    placeholder?: string;

    readOnly?: boolean; // readonly input field
    popupDisabled?: boolean; // disable the dropdown
    disabled?: boolean; // disable input & dropdown

    hideFocusBar?: boolean; // don't show green bar at bottom
    className?: string;
    style?: any;
    inputClassName?: string;
    dropdownColor?: string;
    showSelectionTick?: boolean;

    lineHeight?: number;
    popupWidth?: string;
    popupHeight?: string; //fixed height i.e. "100px"
    popupMaxWidth?: string; //max width "300px"
    popupMaxHeight?: string; //max height "300px"
    popupAlignTop?: boolean;

    // Virtual list for larger data (only render what's visible)
    //  currently doesn't support multi-selection, selection tick
    useVirtualList?: boolean;
    itemHeight?: number;

    blurOnTabKey?: boolean;
    multiLine?: boolean;
    onApplyAll?: Function;
    leftButton?: any;
    doneBtnText?: string;

    //Nested dropdown
    selectedItems?: any[];
    titleProperty?: string;
    idProperty?: string; // ids to compare for duplicate titles
    multiSelection?: boolean;
    filterSelection?: boolean; // filter entries while type
    noFilter?: boolean; // Don't filter when multi selection is true (Filtering enabled by default for multi selection mode)

    noAutoSelect?: boolean; //don't auto select matching entries on text change (INCOMPLETE implementation)

    //for nursing procedures
    showCounter?: boolean;

    // floating label
    label?: string;
    error?: boolean;

    hidden?: boolean;

    onChange?: Function;
    onFocus?: Function;
    onBlur?: Function;

    // disable background and size animation
    dontAnimate?: boolean;

    // Show triangle arrow in the dropdown
    showDropdownArrow?: boolean;

    matchFn?: Function; // custom matching function for text change
    renderItemFn?: (
        item: any,
        onClick: any,
        onMouseEnter: any,
        isSelected: boolean,
        isHovering: boolean,
    ) => JSX.Element;

    children?: React.ReactNode;
};

type TextDropdownState = {
    _expandedMap: any; // for nested dropdown
    _highlightedItem?: any;
    _text?: string;
    _haveFocus: boolean;
};

export class TextDropdown extends BaseElem<TextDropdownProps, TextDropdownState> {
    _loaded = false;

    // render virtual list item binded function
    _RVITEM?: any;

    constructor(props: TextDropdownProps) {
        super(props);
        const self = this;
        self.state = {
            _haveFocus: false,
            _expandedMap: {},
        };
        self._RVITEM = self._renderVListItem.bind(self);
    }

    focus() {
        (this.refs.textField as InputField).focus();
    }

    _setFocus(haveFocus = true) {
        this.setState({_haveFocus: haveFocus});
        this._loaded = true;
        return true;
    }

    _containsAll(value: string, text: string, words: string[]) {
        if (!value || !text) return false;
        if (value.startsWith(text) || value.includes(text)) return true;
        for (let k = (words || []).length; k--; ) {
            if (!value.includes(words[k])) return false;
        }
        return true;
    }

    _onTextChange(comp: InputField, text: string) {
        const self = this,
            props = self.props,
            items = self._getFlatItemsList(props.items);
        const multiSelection = props.multiSelection;

        const lcaseText = text.trim().toLowerCase();
        let item: any | undefined;

        if (props.matchFn) {
            item = props.matchFn(lcaseText);
        } else if (lcaseText) {
            const words = lcaseText.split(' ');

            // first look for items starting with the query text
            item = items.find(v => self._itemTitle(v).toLowerCase().startsWith(lcaseText));

            // next check for contains
            !item && (item = items.find(v => self._itemTitle(v).toLowerCase().includes(lcaseText)));

            // lastly check for containing all words in any order
            !item && (item = items.find(v => self._containsAll(self._itemTitle(v).toLowerCase(), lcaseText, words)));
        }

        let animate = true;
        if (item && (multiSelection || props.filterSelection || props.noAutoSelect)) {
            self._onHover(item);
        } else if (item) {
            self._dropdownItemClick(item, false);
            animate = false;
        } else if (!item && /*!multiSelection &&*/ (props.placeholder || props.label)) {
            self._dropdownItemClick(UNDEF, false);
        }

        self.setState({_text: text});

        item && self.async(() => self._scrollDropdownToSelectedItem(comp, item, 10, animate));
    }

    _getItems(items: any[], isMultiSelection?: boolean): any[] {
        const self = this,
            txtField = self.refs.textField as InputField;
        const txt = txtField.getText().trim().toLowerCase();

        if (!isMultiSelection || !txtField || !txt) {
            let list = items;
            if (self.props.filterSelection) {
                const words = txt.split(' ');
                list = !txt
                    ? list
                    : list.filter((i: any) => self._containsAll(self._itemTitle(i).toLowerCase(), txt, words));
            }
            return list;
        }

        const flatItems = self._getFlatItemsList(items);
        const filteredItems = self.props.noFilter
            ? flatItems
            : flatItems.filter((i: any) => self._itemTitle(i).toLowerCase().indexOf(txt) >= 0);
        return filteredItems.length ? filteredItems : items;
    }

    // change dates with up/down arrows on calendar
    _onKeyPress(comp: InputField, key: string, e: any) {
        const isUpArrow = key === 'ArrowUp';
        const isDownArrow = key === 'ArrowDown';
        const self = this,
            props = self.props;
        const isMultiSelection = props.multiSelection;
        const isFilterSelection = props.filterSelection;
        const currentItem = self.state._highlightedItem || props.selectedItem;

        if (
            currentItem &&
            (isMultiSelection || isFilterSelection || props.noAutoSelect) &&
            e.target.className !== 'td-done' &&
            (key === 'Enter' || (props.readOnly && key === ' '))
        ) {
            self._dropdownItemClick(currentItem, false);
            e.preventDefault();
            return;
        }

        // for nursing procedures left/right arrow keys change the count
        if (props.showCounter && currentItem) {
            const offset = key === 'ArrowLeft' ? -1 : key === 'ArrowRight' ? 1 : 0;
            if (offset) {
                self._updateCount(e, currentItem, self._isItemSelected(currentItem), offset > 0);
                return;
            }
        }

        // move selection on up/down arrow keys
        if (!isUpArrow && !isDownArrow) return;

        // prevent browser default scroll on up/down arrow keys
        e.preventDefault();

        const offset = isDownArrow ? 1 : -1;
        const item = self._getNextItem(currentItem, offset);

        self._onHover(item);
        self._scrollDropdownToSelectedItem(comp, item, 50);

        if (!isMultiSelection && !isFilterSelection) {
            self._dropdownItemClick(item, false);
            comp.updateValue(self._itemTitle(item));
        }
    }

    // get flat list excluding headers, dividers and disabled items
    _getFlatItemsList(items: any[]): any[] {
        const self = this,
            fullList = [];
        for (let i = 0; i < items.length; i++) {
            const ci = items[i];
            if ('header divider disabled'.indexOf(ci.type) >= 0) continue;

            fullList.push(ci);
            if (ci.options && self.state._expandedMap[self._itemTitle(ci)]) {
                fullList.push(...ci.options);
            }
        }
        return fullList;
    }

    _getNextItem(item: any, offset: number): any {
        const self = this,
            fullList = self._getItems(self._getFlatItemsList(self.props.items), self.props.multiSelection);
        let nextIndex = !item
            ? 0
            : (fullList.findIndex((it: any) => self._identifier(it) === self._identifier(item)) + offset) %
              fullList.length;
        if (nextIndex < 0) nextIndex = fullList.length - 1;
        return fullList[nextIndex];
    }

    _identifier(item: any) {
        if (!item) return '';
        const props = this.props;
        const title = item[props.titleProperty || ''];
        const id = item[props.idProperty || ''];
        return id || title || item;
    }
    _itemTitle(item: any) {
        if (!item) return '';
        const title = item[this.props.titleProperty || ''];
        return title === UNDEF ? item : title;
    }

    _scrollDropdownToSelectedItem(_: InputField, item?: any, delay = 100, animate = true) {
        const self = this,
            refs = self.refs,
            props = self.props;
        if (props.popupDisabled) return;
        const dropdownObj = refs.dropdown;
        if (!dropdownObj) return;
        const dropdown = props.useVirtualList ? (dropdownObj as VList).scrollCont() : (dropdownObj as HTMLElement);

        dropdown.style.scrollBehavior = animate ? 'smooth' : '';

        const ITEM_HEIGHT = props.itemHeight || 40; //40px hardcoded in css
        const items = self._getItems(self._getFlatItemsList(props.items), props.multiSelection);
        const selectedItem = item || props.selectedItem;

        const idProp = props.idProperty;
        const selectedIndex = items.findIndex(i =>
            idProp && selectedItem ? i[idProp] === selectedItem[idProp] : i === item || i === selectedItem,
        );

        selectedIndex >= 0 &&
            self.async(() => {
                const maxOffset = dropdown.scrollHeight - dropdown.offsetHeight;
                //-2 is to roughly center the item in screen
                dropdown.scrollTop = clamp(ITEM_HEIGHT * (selectedIndex - 2), 0, maxOffset);
            }, delay);
    }

    _focusAsync(inputField: InputField, item?: any) {
        const self = this,
            onFocusCallback = self.props.onFocus;
        self._setFocus();
        self.setState({_highlightedItem: UNDEF});
        onFocusCallback && onFocusCallback(inputField);
        self.async(
            () => self._scrollDropdownToSelectedItem(inputField, item || self.state._highlightedItem, 1, false),
            1,
        );
    }

    _dropdownItemClick(item: any, blur = true): any {
        const self = this,
            onChange = self.props.onChange;

        if (item && item.options) {
            const map = self.state._expandedMap;
            const propName = self._itemTitle(item);
            map[propName] = !map[propName];
            self.setState({
                _expandedMap: map,
            });
            return;
        }

        self.setState({
            _highlightedItem: item,
        });

        if (onChange) {
            onChange(self, item);
            self.positionPopup();
        }

        blur && self._blur();
        return item;
    }

    _blur() {
        (this.refs.textField as InputField).blur();
        return true;
    }

    _onHover(item: any) {
        const self = this;
        self.debounce(() => self.setState({_highlightedItem: item}), 50);
    }

    _isItemSelected(item: any): any {
        const self = this,
            props = self.props,
            title = self._itemTitle(item);
        if (!props.multiSelection) {
            return self._itemTitle(props.selectedItem) === title;
        } else {
            return (props.selectedItems || []).find((v: any) => self._itemTitle(v) === title);
        }
    }

    _updateCount(e?: any, listItem?: any, proc?: any, increment = false) {
        if (!proc) return;

        proc.quantity = clamp(proc.quantity + (increment ? 1 : -1), 1, 9);
        listItem && this._onHover(listItem);
        e && e.preventDefault();
        e && e.stopPropagation();
    }

    positionPopup() {
        const props = this.props;
        if (props.multiLine && props.multiSelection) {
            const tf = this.refs.textField as InputField;
            tf && tf.positionPopup();
        }
    }

    _renderVListItem(reusable: any, item: any, index: number, /*total*/ _: number, style?: any) {
        const self = this,
            state = self.state,
            props = self.props;
        const text = self._itemTitle(item);

        return (
            <div
                key={self._identifier(item) + index}
                className="dropdown-item td-item"
                style={{
                    ...style,
                    transition: 'none',
                }}
                onClick={_ => self._dropdownItemClick(item, !props.multiSelection)}
                onMouseEnter={_ => self._onHover(item)}
                item-hover={item === state._highlightedItem ? '' : UNDEF}>
                <span>{text}</span>
            </div>
        );
    }

    _renderDropdownItem(item: any, subItem = false, parentItem?: any) {
        const self = this,
            state = self.state,
            props = self.props;
        const text = self._itemTitle(item);
        const parentItemtext = parentItem && self._itemTitle(parentItem);
        if (item.type === 'header') {
            return (
                <div key={text} className="td-item-header">
                    {text}
                </div>
            );
        } else if (item.type === 'divider') {
            return <div key={item.key} className="td-item-divider" />;
        }

        // returns the matching object from selected items
        const itemSelected = self._isItemSelected(item);

        // parent dropdown not expanded
        const collapsed = parentItemtext && parentItem.options && !state._expandedMap[parentItemtext];

        const onClick = () => self._dropdownItemClick(item, !props.multiSelection);
        const onMouseEnter = () => self._onHover(item);
        const isHovering = item === state._highlightedItem;

        if (props.renderItemFn) {
            return props.renderItemFn(item, onClick, onMouseEnter, itemSelected, isHovering);
        }

        return (
            <div
                key={text}
                className="dropdown-item td-item"
                style={{
                    paddingLeft: subItem ? '22px' : UNDEF,
                    maxHeight: collapsed ? '0' : '40px',
                    opacity: collapsed ? 0 : 1,
                }}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                item-selected={itemSelected ? '' : UNDEF}
                item-hover={isHovering ? '' : UNDEF}>
                <span>{text}</span>

                {item.options && (
                    <img
                        src="/dropdown-arrow.svg"
                        alt=""
                        className={'td-dropdown-arrow' + (state._expandedMap[text] ? ' td-arrow-up' : '')}
                    />
                )}

                {!item.options && props.showSelectionTick && (
                    <img src="/check_circle.svg" alt="" className={'td-selected-icon'} />
                )}

                {itemSelected && props.showCounter && (
                    <div className="td-count horizontal center no-shrink no-select" onClick={self.eatClick}>
                        <input
                            type="image"
                            alt=""
                            className={'td-counter ' + (itemSelected.quantity <= 1 ? 'disable' : '')}
                            src="/dec.png"
                            onClick={(e: any) => blur(e) && self._updateCount(e, item, itemSelected, false)}
                            style={{marginLeft: '8px'}}
                        />
                        <span style={{width: '21px', textAlign: 'center'}}>{itemSelected.quantity}</span>
                        <input
                            type="image"
                            alt=""
                            className={'td-counter ' + (itemSelected.quantity >= 9 ? 'disable' : '')}
                            src="/inc.png"
                            onClick={(e: any) => blur(e) && self._updateCount(e, item, itemSelected, true)}
                        />
                    </div>
                )}
            </div>
        );
    }

    render() {
        const self = this,
            state = self.state,
            props = self.props;

        return (
            <div
                className={`td-root ${props.className || ''}${props.disabled ? ' disable' : ''}${
                    props.hidden ? ' gone' : ''
                }`}
                style={props.style}>
                <InputField
                    ref="textField"
                    type="text"
                    width={props.width}
                    maxWidth={props.maxWidth}
                    className={(props.inputClassName || '') + (props.showDropdownArrow ? ' td-arrow' : '')}
                    placeholder={props.placeholder}
                    label={props.label}
                    popupAlignTop={props.popupAlignTop}
                    multiLine={props.multiLine}
                    text={self._itemTitle(props.selectedItem)}
                    readOnly={props.readOnly}
                    hasError={props.error}
                    popupDisabled={props.popupDisabled}
                    // hideFocusBar={props.hideFocusBar}
                    popupWidth={props.popupWidth}
                    popupHeight={props.popupHeight}
                    popupMaxWidth={props.popupMaxWidth}
                    popupMaxHeight={props.popupMaxHeight}
                    disabled={props.disabled}
                    dontAnimate={props.dontAnimate}
                    blurOnTabKey={props.blurOnTabKey === UNDEF ? true : props.blurOnTabKey}
                    blurOnEnterKey={props.multiSelection === UNDEF ? true : !props.multiSelection}
                    onKeyPress={(comp: any, key: string, e: any) => self._onKeyPress(comp, key, e)}
                    onFocus={(comp: any) => self._focusAsync(comp)}
                    onTextChange={(comp: any, text: string) => self._onTextChange(comp, text)}
                    onBlur={(comp: any) => self._setFocus(false) && props.onBlur && props.onBlur(comp)}>
                    {!props.popupDisabled &&
                        self._loaded &&
                        !props.useVirtualList && ( //state._haveFocus &&
                            <div
                                className="dropdown-list td-list"
                                ref="dropdown"
                                style={{color: props.dropdownColor || ''}}>
                                {self._getItems(props.items, props.multiSelection).map((t: any) => {
                                    const items = [self._renderDropdownItem(t)];
                                    t.options &&
                                        t.options.forEach((t2: any) =>
                                            items.push(self._renderDropdownItem(t2, true, t)),
                                        );
                                    return items;
                                })}
                            </div>
                        )}

                    {/* Virtual List */}
                    {props.useVirtualList && !props.popupDisabled && self._loaded && state._haveFocus && (
                        <VList
                            ref="dropdown"
                            className="dropdown-list td-list sbar"
                            style={{color: props.dropdownColor || '', position: 'relative', width: '100%'}}
                            itemHeight={props.itemHeight || 40}
                            itemIdProperty={props.idProperty}
                            items={self._getItems(props.items, props.multiSelection)}
                            selectedItem={state._highlightedItem}
                            itemAtIndex={self._RVITEM}
                        />
                    )}

                    {!props.popupDisabled &&
                        self._loaded && ( //state._haveFocus &&
                            <div className={'td-bottom-bar' + (props.multiSelection ? '' : ' gone')}>
                                {!!props.onApplyAll && (
                                    <Button
                                        tabIndex={0}
                                        className="ha-dialog-done td-done-all"
                                        onClick={() => self._blur() && props.onApplyAll!(self)}>
                                        Apply to selected visits
                                    </Button>
                                )}
                                {props.leftButton}

                                <Button
                                    tabIndex={0}
                                    color="primary"
                                    className="ha-btn-green td-done"
                                    variant="contained"
                                    onClick={() => self._blur()}>
                                    {props.doneBtnText || 'Done'}
                                </Button>
                            </div>
                        )}
                </InputField>
            </div>
        );
    }
}
