export enum Icons {
    GREEN_TICK = '/tickmark_get_care@3x.png',
    GC_MENU_RECIPIENT = '/ic_formMenu_recipient@3x.png',
    GC_MENU_SERVICES = '/ic_formMenu_services@3x.png',
    GC_MENU_DATE_TIME = '/ic_formMenu_date&time@3x.png',
    GC_MENU_LOCATION = '/ic_formMenu_location@3x.png',
    GC_MENU_INSTRUCTION = '/ic_formMenu_instructions@3x.png',
    GC_MENU_PREFERENCE = '/ic_formMenu_preferences@3x.png',
    GC_CARD_SELECTION = '/bg_cardSelected@3x.png',
    LOC_HOME_ADDRESS = '/home90x90.png',
    LOC_HOSPITAL = '/hospital90x90.png',
    LOC_OTHER_LOCATION = '/other-loc90x90.png',
}

export enum PrefTpes {
    LANGUAGE = 'Language',
    GENDER = 'Gender',
    RELIGION = 'Religion',
    RACE = 'Race',
    PET = 'Pet',
}

export enum LocationType {
    HOME = 'home',
    CARE_CENTER = 'care_center',
    OTHER = 'others',
}

export enum CRInputTypes {
    CB = 'case_background',
    LIVING = 'living',
    OHTER_LIVING = 'other_living',
    HOUSING = 'housing',
    OHTER_HOUSING = 'other_housing',
    LIFT = 'lift',
    OHTER_LIFT = 'other_lift',
    PET = 'pet',
    RESAON = 'reason',
    MORE_RESAON = 'more_reason',
    OTHER_RESAON = 'other_reason',
    MC = 'mc',
    MORE_MC = 'more_mc',
    OTHER_MC = 'other_mc',
    infectiousDisease = 'infectiousDisease',
}
