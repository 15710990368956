import {Button, CircularProgress, IconButton, Snackbar as RSnackbar, SnackbarContent} from '@material-ui/core';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ArrowBack, ArrowBackIos, CheckCircle, Close, Error, Warning} from '@mui/icons-material';
import clsx from 'clsx';
import {useLayoutEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import App from '../App';
import '../Common.css';
import {MSG_POP_HISTORY, UNDEF} from '../Constants';
import {getMomentDate, getTodayMomentDate, minsToAMPM} from '../Util/DateUtils';
import {eatClick, guessMinutes, setDocumentScrollEnabled} from '../Util/Util';
import './CommonComponents.css';
import {TextDropdown} from './TextDropdown';

export const LoadingSpinner = () => (
    <div className="loading-spinner">
        <CircularProgress color="primary" />
    </div>
);

const variantIcon: any = {
    success: CheckCircle,
    warning: Warning,
    error: Error,
};

const SnackbarContentStyles = makeStyles((theme: Theme) => ({
    success: {
        backgroundColor: '#32BDC7',
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: '#e6f1f2',
        color: '#4a4a4a',
    },
    warning: {
        backgroundColor: '#FFBC49',
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'pre-line',
    },
    button: {
        color: '#fff',
        fontSize: 13,
        fontWeight: 500,
        marginRight: -8,
        // border: '1px solid #ececec'
    },
}));

function SnackBarContentWrapper(props: any) {
    const classes: any = SnackbarContentStyles();
    const {className, message, onAction, variant, actionTitle, onClose, noClose, ...other} = props;
    const Icon = variantIcon[variant];
    let action: any[] = [];

    onAction &&
        action.push(
            <Button
                key="action"
                color="primary"
                size="small"
                className={classes.button}
                onClick={() => {
                    onAction();
                    onClose();
                }}>
                {actionTitle}
            </Button>,
        );

    !noClose &&
        onClose &&
        action.push(
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                <Close className={classes.icon} />
            </IconButton>,
        );

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    {Icon ? <Icon className={clsx(classes.icon, classes.iconVariant)} /> : null}
                    {message}
                </span>
            }
            action={action}
            {...other}
        />
    );
}

const SnackbarStyles = makeStyles((theme: Theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

export function Snackbar(props: any) {
    const classes = SnackbarStyles();
    const {className, message, isOpen, variant, actionTitle, onAction, onClose, noClose, ...other} = props;

    return (
        <RSnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={isOpen}
            autoHideDuration={1000}
            {...other}>
            <SnackBarContentWrapper
                variant={variant || 'error'}
                className={classes.margin}
                message={message}
                onAction={onAction}
                actionTitle={actionTitle}
                noClose={noClose}
                onClose={onClose}
            />
        </RSnackbar>
    );
}

export function PortalDiv(props: any) {
    const [rootElem] = useState(document.createElement('div'));
    const disableScroll = !!props.disableScroll;

    useLayoutEffect(() => {
        document.body.appendChild(rootElem);
        disableScroll && setDocumentScrollEnabled(false);
        return () => {
            document.body.removeChild(rootElem);
            disableScroll && setDocumentScrollEnabled(true);
        };
    }, [rootElem, disableScroll]);

    return ReactDOM.createPortal(<div>{props.children}</div>, rootElem);
}

export function PortalDialog(props: any) {
    const [rootElem] = useState(document.createElement('div'));
    const disableScroll = !!props.disableScroll;

    useLayoutEffect(() => {
        document.body.appendChild(rootElem);
        disableScroll && setDocumentScrollEnabled(false);

        return () => {
            document.body.removeChild(rootElem);
            disableScroll && setDocumentScrollEnabled(true);
        };
    }, [rootElem, disableScroll]);

    return ReactDOM.createPortal(
        <div className="ha-dialog-bg" onClick={(e: any) => eatClick(e) && props.result && props.result(0)}>
            <div
                className={props.className || 'ha-dialog ha-dialog-appear vertical'}
                onClick={eatClick}
                style={props.style}>
                {props.children}
            </div>
        </div>,
        rootElem,
    );
}

export function OkCancelDialog(props: any) {
    const callback = props.callback;
    const result = (res: number) => callback && callback(res);

    return (
        <PortalDialog result={result} style={props.style}>
            <span className="ha-dialog-title">{props.title}</span>
            <span className="ha-dialog-body">{props.message}</span>
            <div className="horizontal end-justified">
                {props.cancelText && (
                    <Button size="small" className="ha-dialog-cancel" onClick={() => result(1)}>
                        {props.cancelText}
                    </Button>
                )}

                <Button size="small" className="ha-dialog-done" onClick={() => result(2)}>
                    {props.okText || 'OK'}
                </Button>
            </div>
        </PortalDialog>
    );
}

/* incomplete */
export function InputTextDialog(props: any) {
    const callback = props.callback;
    const result = (res: number) => callback && callback(res);

    return (
        <PortalDialog result={result} style={props.style}>
            <span className="ha-dialog-title">{props.title}</span>
            <textarea value={props.value}></textarea>
            <div className="horizontal justified">
                {props.applyAll && (
                    <Button size="small" className="li-done-all" onClick={() => result(1)}>
                        {props.applyAll}
                    </Button>
                )}

                <Button size="small" className="ha-dialog-done" onClick={() => result(2)}>
                    {props.okText || 'OK'}
                </Button>
            </div>
        </PortalDialog>
    );
}

// Time display: am/pm format
// Input/Output: date object
export function TimePicker(props: any) {
    const list = props.timeEntries || [];

    const guessTime = (value: string) => {
        const minutes = guessMinutes(value);
        if (minutes === UNDEF) return;
        const entry = list.find((v: any) => v.mins === minutes);
        return entry || {mins: minutes, name: minsToAMPM(minutes, false, true)};
    };

    const minsToDate = (minutes?: number) => {
        if (minutes === UNDEF) return;
        const mins = minutes % 1440; // 1440 (Need within 24 hours)
        const h = Math.floor(mins / 60);
        const m = mins - h * 60;
        const date = getTodayMomentDate(props.timezone);
        date.set('hour', h);
        date.set('minute', m);
        date.set('second', 0);
        return date.toDate();
    };
    const minsFromDate = (date: Date) => {
        if (!date) return;
        let minutes = 0;
        const momentDate = getMomentDate(date, props.timezone);
        minutes = momentDate.hour() * 60 + momentDate.minute();
        return {mins: minutes, name: minsToAMPM(minutes, false, true)};
    };

    return (
        <TextDropdown
            className={props.className}
            style={props.style}
            width={props.width}
            lineHeight={props.lineHeight}
            inputClassName={props.inputClassName}
            popupMaxHeight="200px"
            placeholder={props.placeholder}
            label={props.label}
            titleProperty="name"
            popupDisabled={props.popupDisabled}
            disabled={props.disabled}
            idProperty="mins"
            matchFn={guessTime}
            onBlur={props.onBlur}
            items={list}
            error={props.error}
            selectedItem={minsFromDate(props.selectedItem)}
            onChange={(_: any, item: any) => props.onChange(item && minsToDate(item.mins))}
        />
    );
}

export function getBackBtn(callback?: () => void) {
    const app = window._app as App;
    if (!app) return null;

    if (app.isIOSDevice) {
        return (
            <Button
                disableRipple
                className="toolbar-ios-back"
                onClick={() => {
                    if (callback) callback();
                    else app.post(MSG_POP_HISTORY);
                }}>
                <div className="h vc">
                    <ArrowBackIos />
                    <span className="ios-back-label">Back</span>
                </div>
            </Button>
        );
    } else {
        return (
            <IconButton
                className="mob-toolbar-icon"
                onClick={() => {
                    if (callback) callback();
                    else app.post(MSG_POP_HISTORY);
                }}>
                <ArrowBack />
            </IconButton>
        );
    }
}
