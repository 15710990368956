import {Icons} from './Util/HomageIcons';

export class GetCareCellModel {
    title: string;
    placeholder: string;
    icon: string;
    details?: string;
    isValidated: boolean;

    constructor(title: string, placeholder: string, icon: string, isValidated: boolean = false) {
        this.title = title;
        this.placeholder = placeholder;
        this.icon = icon;
        this.isValidated = isValidated;
    }
}

export const getCareMenuItems = () => [
    new GetCareCellModel('Care Recipient', 'Who is this request for', Icons.GC_MENU_RECIPIENT),
    new GetCareCellModel('Services', 'Which services do you require', Icons.GC_MENU_SERVICES),
    new GetCareCellModel('Location', 'Where will the care take place', Icons.GC_MENU_LOCATION),
    new GetCareCellModel('Date & Time', 'When do you need the care', Icons.GC_MENU_DATE_TIME),
    new GetCareCellModel('Care Pro Preferences', 'Do you have any preference', Icons.GC_MENU_PREFERENCE),
    new GetCareCellModel('Instructions', 'Is there any special instructions', Icons.GC_MENU_INSTRUCTION),
];

export const MEDICAL_ESCORT = 'Medical Escort';
export const OTHERS = 'Others';

export const TIMEZONES: {[key: string]: string} = {
    Singapore: `Asia/Singapore`, // GMT+8
    Malaysia: 'Asia/Kuala_Lumpur', // GMT+8
    Australia: 'Australia/Melbourne', // GMT+11
};

export const APP_COUNTRY = process.env.REACT_APP_COUNTRY || 'Singapore';

export const CARE_PRO_PROFILE_URL_STAGING: {[key: string]: string} = {
    sg: 'https://testing-sg-v2.smiley-unicorn.info/public-carepro-profile?temp_token=',
    my: 'https://testing-my-v2.smiley-unicorn.info/public-carepro-profile?temp_token=',
    au: 'https://testing-au-v2.smiley-unicorn.info/public-carepro-profile?temp_token=',
};

export const CARE_PRO_PROFILE_URL_PRODUCTION: {[key: string]: string} = {
    sg: 'https://app2.homage.sg/public-carepro-profile?temp_token=',
    my: 'https://app2.homage.com.my/public-carepro-profile?temp_token=',
    au: 'https://app2.homage.com.au/public-carepro-profile?temp_token=',
};

export const APP_IS_STAGING = process.env.REACT_APP_MODE === 'staging';
