import {CircularProgress} from '@material-ui/core';
import {StylesProvider} from '@material-ui/core/styles';
import amplitude from 'amplitude-js';
import Cookies from 'js-cookie';
import React, {Suspense, createContext} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {AppContextType, AppState} from 'types';
import {UserInfo} from './@types/models';
import Api, {getAccessToken, showSnackbar} from './Api';
import './App.css';
import BaseElem from './BaseElem';
import './Common.css';
import {Snackbar} from './Components/CommonComponents';
import {
    ACCESS_TOKEN,
    API_USER_INFO,
    EV_LOGIN_SUCCESS,
    EV_LOGOUT,
    LS_USER_INFO,
    MSG_LOGIN_SUCCESS,
    MSG_LOGOUT,
    MSG_SET_LOADING_STYLE,
    MSG_SHOW_SNACKBAR,
    MSG_TRACK_EVENT,
    MSG_WINDOW_RESIZE,
    SHOW_LOADING,
    UNDEF,
} from './Constants';
import {
    LOG,
    exposeToApp,
    localStorageGet,
    localStorageSave,
    setupExceptionHandler,
    triggerAction,
    triggerRenderComplete,
} from './Util/Util';

const Login = React.lazy(() => import('./Components/Login'));
const EnterpriseMain = React.lazy(() => import('./Components/EnterpriseMain'));

const ONLINE = 'online';
const OFFLINE = 'offline';

export const AppContext = createContext<AppContextType>({} as AppContextType);

class App extends BaseElem<any, AppState> {
    _bindConChange?: any;
    _bindOnFocus?: any;
    _bindOnBlur?: any;
    _bindVisibilityChange?: any;
    _bindOnResize?: any;
    _isVisible = true;
    _lastReq = 0;

    // query param to detect this page is loaded in mobile app's webview
    isMobileApp = window.location.search.includes('b2b_mob_app');

    // detect iOS device
    isIOSDevice = this.isMobileApp && !!navigator.userAgent.match(/iP(?:hone|ad;(?: U;)? CPU) OS (\d+)/);

    constructor(props: any) {
        super(props);
        const self = this;
        const token = getAccessToken();
        const info = localStorageGet(LS_USER_INFO) as UserInfo;

        self.state = {
            accessToken: token,
            userInfo: info,
            isDisconnected: false,
            loading: 0,
            snackbars: [],
        };
        self._enableListeners(true);

        // initialise amplitude
        amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE!);

        // When it's mobile app
        if (self.isMobileApp) {
            exposeToApp((appToken: string) => {
                if (!appToken) return;
                //console.log('Token from app:', appToken);
                localStorageSave(ACCESS_TOKEN, appToken);
                self.setState({accessToken: appToken});
                self.fetchUserInfo(!info);
            }, 'setAccessToken');

            // Notify the mobile apps
            triggerRenderComplete(0);
            self.toggleAttrib('mob-app', true, document.body);
        } else {
            token && self.fetchUserInfo(!info);
            token && self.track(EV_LOGIN_SUCCESS, {mode: 'auto'});
        }
    }

    _enableListeners(isEnabled: boolean) {
        const self = this,
            win = window;
        const listener = isEnabled ? 'addEventListener' : 'removeEventListener';

        if (isEnabled && !self._bindConChange) {
            win._app = self;
            self._bindConChange = self._onConnectionChange.bind(self);
            self._bindOnFocus = self._onFocus.bind(self);
            self._bindOnBlur = self._onBlur.bind(self);
            self._bindVisibilityChange = self._onVisibilityChange.bind(self);
            self._bindOnResize = self._onWindowResize.bind(self);
        }

        win[listener]('focus', self._bindOnFocus);
        win[listener]('blur', self._bindOnBlur);
        win[listener]('resize', self._bindOnResize);

        document[listener]('visibilitychange', self._bindVisibilityChange);

        win[listener](ONLINE, self._bindConChange);
        win[listener](OFFLINE, self._bindConChange);
        self.setListeners(
            isEnabled,
            SHOW_LOADING,
            MSG_SHOW_SNACKBAR,
            MSG_SET_LOADING_STYLE,
            MSG_LOGIN_SUCCESS,
            MSG_LOGOUT,
            MSG_TRACK_EVENT,
        );
    }

    componentDidMount() {
        const self = this;
        self._onConnectionChange();
        self._enableListeners(true);
        setupExceptionHandler();
    }

    componentWillUnmount() {
        this._enableListeners(false);
    }

    _onFocus() {
        const self = this;
        self._isVisible = true;
        // self.post(MSG_ON_PAGE_FOCUS);
    }

    _onBlur() {
        this.async(() => (this._isVisible = !document.hidden), 100);
    }

    _onVisibilityChange() {
        this[document.hidden ? '_onBlur' : '_onFocus']();
    }
    _onWindowResize() {
        const self = this;
        self.debounce(() => self.post(MSG_WINDOW_RESIZE), 10);
    }

    onMessage(messageName: string, payload?: any) {
        const self = this,
            state = self.state;
        if (messageName === SHOW_LOADING) {
            let loadingValue = state.loading + (payload || 0);

            // we only accept values of 1 or 0
            if (loadingValue < 0) {
                loadingValue = 0;
            }
            if (loadingValue > 1) {
                loadingValue = 1;
            }

            self.setState({loading: loadingValue});
        } else if (messageName === MSG_SET_LOADING_STYLE) {
            self.setState({loadingStyle: payload});
        } else if (payload && messageName === MSG_SHOW_SNACKBAR) {
            const snackbars = state.snackbars;
            if (snackbars.find(sb => !sb.actionTitle && sb.message === payload.message)) return;
            payload.open = true;
            snackbars.push(payload);
            self.setState({snackbars: snackbars});

            const duration = payload.duration;
            duration && self.async(() => self._removeSnackbar(payload), duration);
        } else if (messageName === MSG_TRACK_EVENT) {
            self._trackEvent(payload.name, payload.params);
        } else if (messageName === MSG_LOGIN_SUCCESS) {
            self.setState({
                accessToken: Cookies.get(ACCESS_TOKEN),
            });
            !self.isMobileApp && self.track(EV_LOGIN_SUCCESS, {mode: 'manual'});
            //self.fetchUserInfo();
        } else if (messageName === MSG_LOGOUT) {
            self._logOut();
        }
    }

    _removeSnackbar(snackbar: any) {
        const self = this,
            snackbars = self.state.snackbars;
        snackbar.open = false;
        self.setState({snackbars: snackbars});
        self.async(() => self.setState({snackbars: snackbars.filter(s => s !== snackbar)}), 500);
        return true;
    }

    _onConnectionChange() {
        return this.setState({isDisconnected: !navigator.onLine});
    }

    fetchUserInfo(showLoading: boolean = true, onDone?: any) {
        const self = this;
        Api.GET(
            API_USER_INFO,
            response => {
                const data = (response && response.data && response.data.data) as UserInfo;
                let success = false;

                if (data) {
                    if (!data.roles.includes('Care Manager') || !data.available_tabs.includes('get_care')) {
                        showSnackbar(
                            'You are not authorised to access Homage Enterprise. Please contact your account manager',
                            'error',
                            5000,
                        );
                    } else {
                        self.setState({userInfo: data});
                        localStorageSave(LS_USER_INFO, data);
                        // set amplitude user properties
                        self._setUserProperties(data);
                        success = true;
                    }
                }

                onDone && onDone(success);
            },
            showLoading,
            true,
        );
    }

    _logOut() {
        localStorageSave(LS_USER_INFO);
        Cookies.remove(ACCESS_TOKEN);
        localStorageSave(ACCESS_TOKEN); // remove token
        this.setState({
            userInfo: UNDEF,
            accessToken: UNDEF,
        });
        this.track(EV_LOGOUT);
    }

    // Track event directly from web or through mobile app
    _trackEvent = (eventName: string, eventParams?: any) => {
        try {
            LOG('AMPLITUDE:', eventName, eventParams ? 'params: ' + JSON.stringify(eventParams) : '');

            if (this.isMobileApp) {
                triggerAction('track-event', {name: eventName, value: eventParams});
            }
            amplitude.getInstance().logEvent(eventName, eventParams);
        } catch (e) {
            console.log(e);
        }
    };

    // Set amplitude user properties
    _setUserProperties(userInfo: UserInfo) {
        //if(this.isMobileApp) return;
        try {
            const amplitudeClient = amplitude.getInstance();

            const userProps: any = {
                org: userInfo.organisation_name,
                org_code: userInfo.organisation_code,
                cm: userInfo.name,
                cm_group: userInfo.cm_group_name,
                stc: (userInfo.available_tabs || []).includes('open_roles') ? 'yes' : 'no',
                smiley_user_id: userInfo.cm_id,
                country_of_care: userInfo.country,
            };

            const identify = new amplitude.Identify()
                .add('role', userInfo.roles[0])
                .add('user_id', userInfo.id)
                .add('name', userInfo.name);
            amplitudeClient.identify(identify); // Send the Identify call

            amplitudeClient.setUserId(userInfo.id);
            amplitudeClient.setUserProperties(userProps);
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const self = this,
            state = self.state,
            token = state.accessToken,
            userInfo = state.userInfo;

        return (
            <StylesProvider injectFirst>
                <div className="app vertical">
                    {!token && !self.isMobileApp && (
                        <Suspense fallback={<div />}>
                            <Login />
                        </Suspense>
                    )}
                    {token && userInfo && (
                        <Suspense fallback={<div />}>
                            <BrowserRouter>
                                <AppContext.Provider
                                    value={{
                                        _trackEvent: self._trackEvent,
                                    }}>
                                    <EnterpriseMain userInfo={userInfo} />
                                </AppContext.Provider>
                            </BrowserRouter>
                        </Suspense>
                    )}

                    <div className={'loading-spinner' + (state.loading <= 0 ? ' gone' : '')}>
                        <div className="app-spinner-cont" style={state.loadingStyle}>
                            <CircularProgress color="primary" />
                        </div>
                    </div>

                    <div className="app-snackbars">
                        <Snackbar
                            noClose
                            variant="info"
                            isOpen={state.isDisconnected}
                            message="No Internet Connection"
                        />

                        {state.snackbars.map(sb => {
                            return (
                                <Snackbar
                                    key={sb.key}
                                    message={sb.message}
                                    variant={sb.variant}
                                    actionTitle={sb.actionTitle}
                                    isOpen={sb.open}
                                    noClose={!!sb.duration}
                                    onAction={sb.onAction}
                                    onClose={() => self._removeSnackbar(sb)}
                                />
                            );
                        })}
                    </div>
                </div>
            </StylesProvider>
        );
    }
}

export default App;
