import axios from 'axios';
import ReactDOM from 'react-dom/client';
import App from './App';
import {UserProvider} from './UserContext';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

axios.defaults.withCredentials = true;
axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store, must-revalidate';
axios.defaults.headers.common['Pragma'] = 'no-cache';
axios.defaults.headers.common['Expires'] = '0';

// declare global {
//   interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any; }
// }
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <UserProvider>
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
    </UserProvider>,
);

reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
