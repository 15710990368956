import React from 'react';
import {createContext, useState} from 'react';
export const UserContext = createContext();
export class FetchCounts {
    static fetchNavigationCounts = () => {};
}

export const UserProvider = ({children}) => {
    const [unitNumber, setUnitNumber] = useState(null);
    const [blockNumber, setBlockNumber] = useState(null);
    const [buildingName, setBuildingName] = useState(null);
    const [buildingNumber, setBuildingNumber] = useState(null);

    const [streetNo, setStreetNo] = useState(null);
    const [suburbInfo, setSuburbInfo] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [suburb, setSuburb] = useState(null);
    const [state, setState] = useState(null);
    const [district, setDistrict] = useState(null);
    const [streetName, setStreetName] = useState(null);
    const [city, setCity] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [floorNumber, setFloorNumber] = useState(null);
    const [wardRoom, setWardRoom] = useState(null);
    const [suburbs, setSuburbs] = useState(['']);
    const [location, setLocation] = useState({});
    const fetchNavigationCounts = () => {};

    return (
        <UserContext.Provider
            value={{
                unitNumber,
                setUnitNumber,
                blockNumber,
                setBlockNumber,
                buildingName,
                setBuildingName,
                buildingNumber,
                setBuildingNumber,
                streetNo,
                setStreetNo,
                suburbInfo,
                setSuburbInfo,
                postalCode,
                setPostalCode,
                suburb,
                setSuburb,
                state,
                setState,
                district,
                setDistrict,
                streetName,
                setStreetName,
                city,
                setCity,
                location,
                setLocation,
                suburbs,
                setSuburbs,
                latitude,
                setLatitude,
                longitude,
                setLongitude,
                floorNumber,
                setFloorNumber,
                wardRoom,
                setWardRoom,
                fetchNavigationCounts,
            }}>
            {children}
        </UserContext.Provider>
    );
};
