////// API ENDPOINTS ///////
export const API_AUTH_TOKEN = 'oauth/token';
export const API_USER_INFO = 'user';
export const API_USER_LOGOUT = 'user/logout';
export const API_RESET_PASSWORD = 'user/reset_password';
export const API_ALL_RECIPIENTS = 'cr_groups/care_recipients';
export const API_GET_STATE = 'states';
export const API_POST_DISTRICT = 'districts';

export const API_VISIT_SUMMARY = (visit_id: string, cr_id: string) => `visits/${visit_id}/${cr_id}/visit_summary`;
export const API_VISIT_DETAILS = 'vwo/visits/'; // :visit_id';

export const API_VISIT_SUMMARIES = 'cr_groups/visit_summaries?limit=300';
export const API_POST_VISIT_SUMMARIES = 'cr_groups/visit_summaries';

// fetch upcoming and history visits
export const API_POST_VISITS = 'cr_groups/visits';

export const API_UPCOMING_VISITS = 'cr_groups/visits/upcoming';
export const API_HISTORY_VISITS = 'cr_groups/visits/history';
export const API_NEW_CR = 'caremanager/care_recipient/new';
export const API_NEW_CF = 'caremanager/care_facility/new';
export const API_GET_RECENT_CR_INFO = 'caremanager/recent_cr_info/'; //:cr_id
export const API_GET_CM_CONFIG = 'caremanager/config';
export const API_GET_CR_INFO = 'caremanager/care_recipient/'; //:cr_id
export const API_POST_CR_INFO = API_GET_CR_INFO;
export const API_GET_CF_INFO = 'caremanager/care_facility/'; //:cf_id
export const API_POST_CF_INFO = API_GET_CF_INFO;
export const API_GET_CITY_STATES = 'caremanager/care-recipient/city-states';
export const API_GET_CARE_MANAGER_SETTINGS_LIST = 'caremanager/settings_list';
export const API_GET_CARE_MANAGER_EMAIL_NOTIFICATION_SETTINGS =
    'caremanager/settings_list/email_notifications?organisation_id=';
export const API_POST_CARE_MANAGER_EMAIL_NOTIFICATION_SETTINGS = 'caremanager/settings_list/email_notifications';
export const API_GET_LONG_VISIT = 'visits/long'; // :cr_id/:startDate/:endDate
export const API_GET_CANCELLATION_REASONS = 'visits/update/get-reasons';
export const API_POST_CANCEL_REVIEW = 'visits/cancel/review';
export const API_POST_CANCEL_VISITS = 'visits/cancel';

export const API_SCHEDULE_APPOINTMENT = 'caremanager/schedule_appointment';
export const API_CHANGE_APPOINTMENT = 'caremanager/change_appointment_status';
export const API_TERMINATE_SERVICE = 'caremanager/terminate_service';
export const API_SIDEBAR_COUNTERS = 'caremanager/enterprise/counts';
export const getSuburbs = (id: string) => `suburb-state-postcode/${id}`;
export const API_UPLOAD = 'upload';

export const API_DASHBOARD = 'caremanager/dashboard';
export const API_EXPORT = 'caremanager/export';

////// API ENDPOINTS ///////

////// COLORS ///////
export const GREEN_COLOR = '#32bdc7';
export const ORANGE_COLOR = '#ffbc49';
export const PAST_VISIT_COLOR = '#d0d0d0';
////// END COLORS ///////

/// Post message types
export const SHOW_LOADING = 'show-loading';
export const MSG_SHOW_SNACKBAR = 'show-snackbar';
export const MSG_SET_LOADING_STYLE = 'style-loading';
// export const MSG_ON_PAGE_FOCUS = 'msg-on-page-focus';
export const MSG_LOGIN_SUCCESS = 'on-login';
export const MSG_LOGOUT = 'on-logout';
export const MSG_POP_HISTORY = 'pop-history';
export const MSG_PUSH_HISTORY = 'push-history';
export const MSG_REPLACE_HISTORY = 'rep-history';
export const MSG_TOGGLE_DRAWER = 'toggle-drawer';
export const MSG_REFRESH_COUNTS = 'refresh-counters';
export const MSG_TRACK_EVENT = 'track-event';
export const MSG_WINDOW_RESIZE = 'win-resize';
export const MSG_CR_SELECTED = 'on-cr-selected';
export const MSG_REFRESH_RECIPIENTS = 'on-cr-edited';
export const CARE_TYPE_CHANGED = 'CARE-TYPE-CHANGED';
export const SAVE_BUTTON_SERVICES = 'SAVE_BUTTON';
export const REQUIRE_MANUAL_TRANSFER_ERROR = 'REQUIRE_MANUAL_TRANSFER_ERROR';
export const CLEAR_FORM_P2P = 'CLEAR_FORM_P2P';

/// End Post message types

//// Cookie constants
export const ACCESS_TOKEN = 'access_token';
//// End Cookie constants

//// Local storage (User defaults/SharedPreference) ////
export const LS_USER_INFO = 'user_info';
export const LS_SELECTED_CR = 'cr';
export const LS_OTHER_LOCATIONS = 'other_locations';

//// End Local storage (User defaults/SharedPreference) ////

//DATE
export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const UNDEF = undefined;

// Url constants
export const EM_REQUEST = 'request';
export const EM_NEW = 'new';
export const EM_CHANGE = 'change';
export const EM_CANCEL = 'cancel';
export const EM_TERMINATE = 'terminate';

export const EM_APPOINTMENTS = 'appointments';
export const EM_IN_REVIEW = 'inreview';
export const EM_ONGOING = 'ongoing';
export const EM_UPCOMING = 'upcoming';
export const EM_RECENT = 'recent';

export const EM_HISTORY = 'history';

export const EM_RECIPIENTS = 'recipients';
export const EM_MANAGE = 'manage';
export const EM_VISIT_SUMMARIES = 'visit_summaries';
export const EM_VISIT_SUMMARIES_DETAILS = 'visit_summaries_details';
export const EM_SETTINGS = 'settings';
export const EM_DASHBOARD = 'dashboard';
export const EM_REPORTS = 'reports';

export const URLS = [
    `/${EM_REQUEST}/${EM_NEW}`, // 0
    `/${EM_REQUEST}/${EM_CHANGE}`, // 1
    `/${EM_REQUEST}/${EM_CANCEL}`, // 2
    `/${EM_REQUEST}/${EM_TERMINATE}`, // 3
    `/${EM_APPOINTMENTS}/${EM_IN_REVIEW}`, // 4
    `/${EM_APPOINTMENTS}/${EM_ONGOING}`, // 5
    `/${EM_APPOINTMENTS}/${EM_UPCOMING}`, // 6
    `/${EM_APPOINTMENTS}/${EM_HISTORY}`, // 7
    `/${EM_RECIPIENTS}/${EM_NEW}`, // 8
    `/${EM_RECIPIENTS}/${EM_MANAGE}`, // 9
    `/${EM_VISIT_SUMMARIES}/${EM_VISIT_SUMMARIES_DETAILS}`, // 10
    `/${EM_SETTINGS}`, // 11
    `/${EM_DASHBOARD}`, // 12
    `/${EM_REPORTS}`, // 13
];

// Get care url components
export const COMPONENT_PATHS = ['recipient', 'services', 'location', 'date', 'preferences', 'instructions'];

//Care Types
export const P2P_CARE = 'p2p';
export const HBC_KEY = 'home_based_care';
export const FBC_KEY = 'facility_based_care';

// amplitude constants
export const EV_HOME_SCREEN = 'home_screen';
export const EV_LOGIN = 'login';
export const EV_LOGIN_SUCCESS = 'login_success';
export const EV_LOGOUT = 'logout';
export const EV_FORGOT_PASSWORD = 'forgot_password';
export const EV_SET_PASSWORD_SCREEN = 'set_password_screen';
export const EV_SET_PASSWORD = 'set_password';
export const EV_SET_PASSWORD_SUCCESS = 'set_password_success';
export const EV_VIEW_VISITS_ONGOING = 'view_visits_ongoing';
export const EV_VIEW_VISITS_IN_REVIEW = 'view_visits_in_review';
export const EV_VIEW_VISITS_UPCOMING = 'view_visits_upcoming';
export const EV_VIEW_VISITS_RECENT = 'view_visits_recent';
export const EV_VIEW_VISITS_HISTORY = 'view_visits_history';
export const EV_MANAGE_RECIPIENTS = 'manage_recipients';
export const EV_SELECT_RECIPIENT = 'select_recipient';
export const EV_EDIT_RECIPIENT = 'edit_recipient';
export const EV_EDIT_RECIPIENT_SAVE = 'edit_recipient_save';
export const EV_CHANGE_VISIT = 'change_visit';
export const EV_CHANGE_VISIT_CONFIRM = 'change_visit_confirm';
export const EV_CANCEL_VISIT = 'cancel_visit';
export const EV_CANCEL_VISIT_CONFIRM = 'cancel_visit_confirm';
export const EV_TERMINATE_SERVICES = 'terminate_services';
export const EV_TERMINATE_SERVICES_CONFIRM = 'terminate_services_confirm';
export const EV_NEW_RECIPIENT_FORM = 'new_recipient_form';
export const EV_CREATE_RECIPIENT_CONFIRM = 'create_recipient_confirm';
export const EV_CREATE_RECIPIENT_FAIL = 'create_recipient_fail';
export const EV_EDIT_RECIPIENT_FAIL = 'edit_recipient_fail';
export const EV_CREATE_RECIPIENT_SUCCESS = 'create_recipient_success';
export const EV_NEW_BOOKING = 'new_booking';
export const EV_CONFIRM_BOOKING = 'confirm_booking';
export const EV_VISIT_BOOKING_SUCCESS = 'visit_booking_success';
export const EV_SERVICES_SAVE = 'services_save';
export const EV_DATE_TIME_SAVE = 'date_time_save';
export const EV_LOCATION_SAVE = 'location_save';
export const EV_PREFERENCES_SAVE = 'preferences_save';
export const EV_VIEW_CP_PROFILE = 'view_cp_profile';
export const EV_VIEW_VISIT_SUMMARY = 'view_visit_summary';
export const EV_SELECT_VISIT_SUMMARY = 'select_visit_summary';
export const EV_DOWNLOAD_VISIT_SUMMARY = 'download_visit_summary';
export const EV_SELECT_FILTERS = 'select_filters';
export const EV_APPLY_FILTERS = 'apply_filters';
export const EV_SELECT_ALL_QUICK_FILTERS = 'select_quick_filters_all';

export const EV_CREATE_NEW_CARE_RECIPIENT = 'create_new_care_recipient';
export const EV_CREATE_NEW_CARE_RECIPIENT_INDIVIDUAL = 'create_new_care_recipient_individual';
export const EV_CREATE_NEW_CARE_RECIPIENT_GROUP = 'create_new_care_recipient_group';
export const EV_NEW_BOOKING_ADHOC = 'new_booking_adhoc';
export const EV_NEW_BOOKING_RECURRING = 'new_booking_recurring';
export const VIEW_VISITS_UPCOMING_DOWNLOAD = 'view_visits_upcoming_download';
export const NEW_APPOINTMENT = 'new_appointment';
export const UPCOMING_APPOINTMENT = 'upcoming_appointment';
export const EV_VIEW_DASHBOARD = 'view_dashboard';
export const EV_CLICK_DASHBOARD_WIDGET = 'click_dashboard_widget';

//Submit Feedback
export const SUBMIT_FEEDBACK = 'submit_feedback';
