import moment from 'moment-timezone';
import {LocationTimezone, TimeZoneConfig, UserInfo} from '../@types/models';
import {LS_USER_INFO, UNDEF} from '../Constants';
import {APP_COUNTRY, TIMEZONES} from '../DataConstants';
import {localStorageGet} from '../Util/Util';

export function getMonths(): string[] {
    return [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
}

export function getShortMonths(): string[] {
    return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
}

export function getDayNames(offset = 0): string[] {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days.map((_: any, i: number) => days[(i + offset) % 7]);
}

// 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
export function getShortWeekDays(offset = 0): string[] {
    return getDayNames(offset).map((d: string) => d.substr(0, 3));
}

// 'S', 'M', 'T', 'W', 'T', 'F', 'S'
export function getMiniWeekDays(offset = 0): string[] {
    return getDayNames(offset).map((d: string) => d[0]);
}

// week start from Monday
export function firstDayOfWeek(): number {
    return 1;
}

// convert military time to am pm format
export function hoursToAMPM(militaryHours: number): string {
    const ampm = militaryHours >= 12 && militaryHours < 24 ? 'PM' : 'AM';
    const t = militaryHours % 12 || 12;
    return t + ' ' + ampm;
}

// Convert minutes to AM PM format
// Exmaple input: 780  output: 1pm  compactFormat=false 01:00 pm
// Exmaple input: 810  output: 1:30pm  compactFormat=false 01:30 pm
export function minsToAMPM(minutes: number, compactFormat = true, capitalise = false): string {
    const mins = minutes % 1440; // 1440 (Need within 24 hours)
    const militaryHours = Math.floor(mins / 60);

    let ampm = militaryHours >= 12 && militaryHours < 24 ? 'pm' : 'am';
    capitalise && (ampm = ampm.toUpperCase());
    const h = militaryHours % 12 || 12;
    const m = mins - militaryHours * 60;

    if (compactFormat) {
        let min = m ? ':' + m : '';
        return `${h}${min}${ampm}`;
    } else {
        let hr = h < 10 ? '0' + h : String(h);
        let min = ':' + (m < 10 ? '0' + m : m);
        return `${hr}${min} ${ampm}`;
    }
}

// Convert minutes to AM PM format
// Exmaple input: 780  output: 1pm  compactFormat=false 01:00 pm
// Exmaple input: 810  output: 1:30pm  compactFormat=false 01:30 pm
export function dateToAMPM(date?: Date, compactFormat = true): string {
    return !date ? '' : minsToAMPM(date.getHours() * 60 + date.getMinutes(), compactFormat);
}

// Convert minutes to Military format
// Exmaple input: 780  output: 13:00
export function minsToMilitary(minutes: number) {
    const mins = minutes % 1440; // 1440 (Need within 24 hours)
    const h = Math.floor(mins / 60);
    const m = mins - h * 60;
    return (h < 10 ? '0' + h : String(h)) + ':' + (m < 10 ? '0' + m : String(m));
}

// Convert javascript date to YYYY-MM-DD format
export function dateFormat(d?: Date): string | undefined {
    if (!d) return UNDEF;
    let year = d.getFullYear().toString();
    let month = (d.getMonth() + 101).toString().slice(-2);
    let date = (d.getDate() + 100).toString().slice(-2);
    return year + '-' + month + '-' + date;
}

export function dateToDDMMYYYY(d?: Date): string | undefined {
    const s = dateFormat(d);
    if (s) {
        return s.split('-').reverse().join('/');
    }
    return s;
}

// create date object by taking care of timezone offset input format: YYYY-MM-DD
export function toDate(d?: string): Date | undefined {
    try {
        if (!d) return UNDEF;
        return new Date(new Date(d).getTime() + new Date(d).getTimezoneOffset() * 60 * 1000);
    } catch (e) {
        return UNDEF;
    }
}

//YYYY-MM-DD to Date object
export function toYYYYMMDD(dateStr?: string) {
    if (!dateStr) return UNDEF;
    try {
        const [year, month, day] = dateStr.split('-');
        if (!year || month === UNDEF || day === UNDEF) return UNDEF;
        const ret = new Date();
        ret.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
        return ret;
    } catch (e) {
        return UNDEF;
    }
}
export function dateFromDDMMYYYY(dateStr?: string) {
    if (!dateStr) return UNDEF;
    try {
        const [day, month, year] = dateStr.split('/');
        if (year === UNDEF || month === UNDEF || day === UNDEF) return UNDEF;
        const ret = new Date();
        ret.setFullYear(parseInt(year), parseInt(month) - 1, parseInt(day));
        return ret;
    } catch (e) {
        return UNDEF;
    }
}

// Parse date & time string i.e: 2019-09-27T18:00, 2019-11-30T21:00:00.000Z
export function parseDate(dateString: string, offsetTimezone = false): Date {
    //if((dateString as any).getDate) return dateString as any;
    //const parts = dateString.split(new RegExp(/[-T:]+/)).map(v => parseInt(v));
    //const date = new Date(parts[0], parts[1]-1, parts[2], parts[3], parts[4], 0);
    //return date;
    return new Date(dateString);
}

/**
 * Add/Subtract days from date
 */
export function dateAdd(date: any, daysToAdd: number): Date | null {
    if (!date) return null;
    const newDate = new Date(date);
    daysToAdd && newDate.setDate(date.getDate() + daysToAdd);
    return newDate;
}

// return true if date is in the past or null/undefined date
export function isPastDate(date?: Date | null, today?: Date, compareTimeAlso = false): boolean {
    const _today = today || new Date();
    !compareTimeAlso && _today.setHours(0, 0, 0, 0);
    return !date || date.getTime() < _today.getTime();
}

// return true if date is in the future
export function isFutureDate(date: Date): boolean {
    const today = new Date();
    return date && date.getTime() > today.getTime();
}

// return true if dates are same ignoring time part
export function areDatesSame(d1?: Date, d2?: Date, compareTimeAlso = false): boolean {
    if (d1 === d2) return true;
    if (!d1 || !d2) return false;

    let ret = d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();

    if (ret && compareTimeAlso) {
        ret = d1.getHours() === d2.getHours() && d1.getMinutes() === d2.getMinutes();
    }
    return ret;
}

export function calculateDurationHourMinute(timeStart: Date, timeEnd: Date) {
    const msDiff = timeEnd.getTime() - timeStart.getTime();
    const min = msDiff / 60000;
    const hour = Math.floor(msDiff / 3600000);
    const remainderMin = Math.floor(min - 60 * hour);
    return {
        hours: hour,
        minutes: remainderMin,
    };
}

// Return formatted duration between two dates
//  long forms: 2 hours, 1 hour 30 minutes, 20 hours 15 minutes
//  short forms: 2h, 1.5h, 20h 15m
export function getDurationString(timeStart: Date, timeEnd: Date, shortForm = false): string {
    if (!timeStart || !timeEnd) return '';
    const {hours, minutes} = calculateDurationHourMinute(timeStart, timeEnd);

    let ret = shortForm
        ? `${hours}${minutes === 30 ? '.5h' : minutes > 0 ? 'h ' + minutes + 'm' : 'h'}`
        : `${hours} hour${hours > 1 ? 's' : ''}${minutes > 0 ? ' ' + minutes + ' minutes' : ''}`;

    if (ret.startsWith('0h ')) return ret.slice(3);
    if (ret.startsWith('0hour ')) return ret.slice(6);
    return ret;
}

/**
 * Convert date to formatted string
 * @param d Should be date object or parseable date string in format '2019-09-27T18:00'
 * @returns Sun 20 Aug 2019
 */
export function formatDate(d: any, offsetTimezone = false): string {
    if (!d) return '';
    if (typeof d === 'string') d = parseDate(d);

    const date = d.getDate(),
        month = d.getMonth(),
        year = d.getFullYear();
    const monthName = getShortMonths()[month];

    return [getShortWeekDays()[d.getDay()], date, monthName, year].join(' ');
}

/**
 * Convert date to "27 Sep"
 * @param d Should be date object or parseable date string in format '2019-09-27T18:00'
 * @returns 27 Sep
 */
export function formatDateDDMMM(d: any): string {
    const parts = formatDate(d).split(' ');
    return (parts[1] || '') + ' ' + (parts[2] || '');
}

// return time portion from date object i.e. "10:00AM"
export function formatTime(date: Date): string {
    return !date ? '' : dateToAMPM(date, false).toUpperCase();
}

// Get Full Month name from date (March, April etc...)
export function getMonthName(d: any): string {
    if (!d) return '';
    if (typeof d === 'string') d = parseDate(d);
    return getMonths()[d.getMonth()];
}

// Get Full weekDay name from date (Sunday, Monday etc..)
export function getWeekdayName(d: any): string {
    if (!d) return '';
    if (typeof d === 'string') d = parseDate(d);
    return getDayNames()[d.getDay()];
}

// returns 1,2,3,4,5
export function nthWeekdayNum(d: Date): number {
    const day = d.getDay();
    let num = 1,
        d2: any = new Date(d);
    while (d2 && d2.getDate() !== 1) {
        d2 = dateAdd(d2, -1);
        if (d2 && d2.getDay() === day) num++;
    }
    return num;
}

// examples:
//  baseDate: 30 nov 2019   d: 30 nov 2019  returns: last sat of dec, 28-dec 2019
//  baseDate: 30 nov 2019   d: 28 dec 2019  returns: last sat of jan, 25-jan 2020
//  baseDate: 14 nov 2019   d: 14 nov 2019  returns: 2nd tue of dec, 12-dec 2019
export function getSameDayNextMonth(baseDate: Date, d: Date): Date {
    let d2: any = new Date(d);
    const nthDay = nthWeekdayNum(baseDate);
    while (d2.getMonth() <= d.getMonth() && d2.getFullYear() <= d.getFullYear()) {
        d2 = dateAdd(d2, 7);
    }
    let curDay = 1;

    if (nthDay < 5) {
        while (curDay < nthDay) {
            d2 = dateAdd(d2, 7);
            curDay++;
        }
    } else {
        // next month might not have 5th tue,wed etc.
        d2 = dateAdd(d2, 7 * 3);
        const d3: any = dateAdd(d2, 7);
        d2 = d3.getMonth() === d2.getMonth() ? d3 : d2;
    }

    return d2;
}

//SO: https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number/13627586
export function nth(n: number) {
    return n + ([UNDEF, 'st', 'nd', 'rd'][(n / 10) % 10 ^ 1 && n % 10] || 'th');
}

// Returns: 2nd Sunday, 3rd Friday, 4th Sunday, last Monday etc..
export function nthWeekday(date: any): string {
    if (!date) return '';
    let d: any = typeof date === 'string' ? parseDate(date) : new Date(date);

    const day = d.getDay(),
        dayName = getDayNames()[day];
    let num = 1;

    while (d.getDate() !== 1) {
        d = dateAdd(d, -1);
        if (d.getDay() === day) num++;
    }

    switch (num) {
        case 1:
            return `1st ${dayName}`;
        case 2:
            return `2nd ${dayName}`;
        case 3:
            return `3rd ${dayName}`;
        case 4:
            return `4th ${dayName}`;
        case 5:
            return `last ${dayName}`;
        default:
            return '';
    }
}

/**
 * Convert dates to formatted string
 * @param d Should be date objects or parseable date strings in format '2019-09-27T18:00'
 * @returns Aug 20-23, Aug 27-30, Sep 10, 12-14
 */
export function formatMultipleDates(dates?: any[]): string {
    if (!dates || !dates.length) return '';

    const jsDates = dates.map(d => (typeof d === 'string' ? parseDate(d) : d));
    jsDates.sort((a, b) => a.getTime() - b.getTime());

    const output: string[] = [];
    const shortMonths = getShortMonths();
    let incStart = '';

    for (let i = 0; i < jsDates.length; i++) {
        const d = jsDates[i];
        const prevDate = i > 0 && jsDates[i - 1];

        const monthName = shortMonths[d.getMonth()];
        const prevMonth = i === 0 ? '' : shortMonths[prevDate.getMonth()];
        const date = d.getDate();

        const isIncrementalDate =
            i > 0 &&
            prevDate.getDate() + 1 === date &&
            prevDate.getMonth() === d.getMonth() &&
            prevDate.getFullYear() === d.getFullYear();

        if (isIncrementalDate) {
            const lastIdx = output.length - 1;
            if (!incStart) {
                incStart = output[lastIdx];
                output[lastIdx] = incStart + '-' + date;
            } else {
                output[lastIdx] = incStart + '-' + date;
            }
        } else {
            incStart = '';
            output.push(monthName !== prevMonth ? monthName + ' ' + date : date);
        }
    }
    return output.join(', ');
}

// Fix visit date to be multiple of 15 mins
export function fixVisitDate(startDate: Date) {
    const rem = startDate.getMinutes() % 15;
    const ret = new Date(startDate);
    if (rem !== 0) {
        ret.setMinutes(ret.getMinutes() - rem);
    }
    ret && ret.setSeconds(0);
    return ret;
}

// Return undefined if visit duration is valid, error message otherwise
// min 1 hr, max 24 hr
// duration multiple of 30 mins
export function validateDuration(sDate: Date, eDate: Date) {
    //if(eDate < sDate) {
    //  eDate.setDate(eDate.getDate() + 1);
    //}
    const diff = calculateDurationHourMinute(sDate, eDate);
    const mins = diff.hours * 60 + diff.minutes;
    const hours = mins / 60;
    let errorMsg;

    if (hours < 1) {
        errorMsg = 'Duration should be at least 1 hour.';
    } else if (hours > 24) {
        errorMsg = "Duration can't be more than 24 hours.";
    } else if (snapToNearest30Mins(mins) !== mins) {
        const intHour = Math.floor(hours);
        let durString = getDurationString(sDate, eDate, true);
        durString = durString.replace('h 15m', `h or ${intHour}.5h`);
        durString = durString.replace('h 45m', `.5h or ${intHour + 1}h`);
        errorMsg = `Invalid duration. Please change it to ${durString}`;
    }

    return errorMsg;
}

// snap minutes value to nearest 30 mins
export function snapToNearest30Mins(minutes: number): number {
    return Math.round(minutes / 30) * 30;
}

// validate duration
// minimum [minDurationInHours], max 24 hours, duration should be multiple of 30 mins
export function fixVisitEndDate(sDate: Date, eDate: Date, minDurationInHours: number = 1) {
    if (eDate < sDate) {
        eDate.setDate(eDate.getDate() + 1);
    }

    const startDateTime = sDate.getTime();
    const diff = calculateDurationHourMinute(sDate, eDate);
    const mins = snapToNearest30Mins(diff.hours * 60 + diff.minutes);
    const hours = mins / 60;
    let retDate;

    if (hours < minDurationInHours) {
        // add minDurationInHours to start date
        retDate = new Date(startDateTime + minDurationInHours * 3600000); //minDurationInHours * 60 * 60 * 1000
    } else if (hours >= 24) {
        // add 24 hour to start date
        retDate = new Date(startDateTime + 86400000); //24 * 60 * 60 * 1000
    } else {
        retDate = new Date(startDateTime + mins * 60 * 1000);
    }

    retDate && retDate.setSeconds(0);
    return retDate;
}

// // Return date in ISO string taking into account local timezone
// export function getLocalDate(d:Date){
//   var tzoffset = d.getTimezoneOffset() * 60000; //offset in milliseconds
//   return toISOLocal(new Date(d.getTime() - tzoffset));
// }

//This generates date string like this '2019-11-30T21:00:00.000Z'
export function toISOLocal(date: Date) {
    let d = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    const z = (n: number) => (n < 10 ? '0' : '') + n;
    return (
        d.getFullYear() +
        '-' +
        z(d.getMonth() + 1) +
        '-' +
        z(d.getDate()) +
        'T' +
        z(d.getHours()) +
        ':' +
        z(d.getMinutes()) +
        ':' +
        z(d.getSeconds()) +
        '.000Z'
    );
}

// export function toISOUtc(date: Date){
//   let minutes = date.getMinutes() >= 10 ? date.getMinutes() : ('0' + date.getMinutes());
//   let hours = date.getHours() >= 10 ? date.getHours() : ('0' + date.getHours());
//   return date.getFullYear() + '-' +
//     ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
//     ('0' + date.getDate()).slice(-2) + 'T' + hours + ":" + minutes + ":00.000+00:00";
// }

// return time entries for time picker
export function getTimeEntries() {
    const mins: any[] = [];
    for (let i = 0; i < 96; i++) {
        mins.push({mins: i * 15, name: minsToAMPM(i * 15, false, true)});
    }
    return mins;
}

export function dateDiffHours(date1: Date, date2: Date) {
    return Math.abs(date1.getTime() - date2.getTime()) / 3.6e6;
}
export function dateDiffDays(date1: Date, date2: Date) {
    const d1 = new Date(date1),
        d2 = new Date(date2);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    const diff = d2.getTime() - d1.getTime();
    return isNaN(diff) ? NaN : diff / (1000 * 60 * 60 * 24);
}

// format = "03:15 PM" (assuming input is in correct format)
// returns value in minutes or undefined i.e. '01:30 AM' = 90
export function timeStringToMins(t: string) {
    const regex = '( |:)';
    const components: any = t && t.toLowerCase().split(new RegExp(regex));
    //["03", ":", "15", " ", "pm"]

    if (components && components.length === 5) {
        const h = components[0] % 12;
        const m = components[2] % 60;
        const am = components[4] === 'am';
        return (h + (!am ? 12 : 0)) * 60 + (m || 0);
    }
}

// format = "03:15 PM" (assuming input is in correct format)
// returns value date object
export function timeStringToDate(t?: string) {
    if (!t) {
        return UNDEF;
    }
    const regex = '( |:)';
    const components: any = t && t.toLowerCase().split(new RegExp(regex));
    //["03", ":", "15", " ", "pm"]

    if (components && components.length === 5) {
        const h = components[0] % 12;
        const m = components[2] % 60;
        const am = components[4] === 'am';

        const d = new Date();
        d.setHours(h + (!am ? 12 : 0), m || 0, 0, 0);
        return d;
    }
}

export const getTimezone = (location: LocationTimezone, timezones: TimeZoneConfig) => {
    let timezone = 'Asia/Singapore';
    if (timezones.timezones && location) {
        const {country, city} = location;
        if (timezones.is_single_tz) {
            timezone = timezones.timezones[country] || timezone;
        } else {
            if (!city) {
                timezone = TIMEZONES[country];
            } else {
                timezone = timezones.timezones[city];
            }
        }
    }
    return timezone;
};

export const getCurrentTimeFromUserTimezone = () => {
    const timezone = moment.tz.guess();
    return moment().tz(timezone);
};

export const getCurrentTimeFromUserTimezoneISO = () => {
    return getCurrentTimeFromUserTimezone().toISOString();
};

export const formatDateIsoWithFormat = (isoDate: string, location: LocationTimezone, format: string) => {
    if (!isoDate) return moment().format(format);

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);

    return moment(isoDate).tz(timezone).format(format);
};

export const formatDateIso = (isoDate: string, location: LocationTimezone) => {
    if (!isoDate) return moment().format('ddd, DD MMM yyyy');

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    return moment(isoDate).tz(timezone).format('ddd, DD MMM yyyy');
};

export const formatDateTimeIso = (isoDate: string, location: LocationTimezone, fallback: boolean = true) => {
    if (!isoDate) {
        return fallback ? moment().format('ddd, DD MMM yyyy, h:mm a') : null;
    }

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    return moment(isoDate).tz(timezone).format('ddd, DD MMM yyyy, h:mm a');
};

export const formatFullDateIso = (isoDate: string, location: LocationTimezone) => {
    if (!isoDate) return moment().format('DD MMM YYYY, ddd');

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    return moment(isoDate).tz(timezone).format('ddd, DD MMM YYYY');
};

export const formatClockTime = (isoDate: string, location: LocationTimezone) => {
    if (!isoDate) return moment().format('DD MMM YYYY, h:mm A');

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    return moment(isoDate).tz(timezone).format('DD MMM YYYY, h:mm A');
};

export const formatStartEndTime = (startDateIso: string, endDateIso: string, location: LocationTimezone) => {
    if (!startDateIso || !endDateIso) return `${moment().format('h:mm a')} - ${moment().format('h:mm a')}`;

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    const startTime = moment(startDateIso).tz(timezone).format('h:mma');
    const endTime = moment(endDateIso).tz(timezone).format('h:mma');
    return `${startTime} - ${endTime}`;
};

export const formatStartEndTimeWithTz = (startDateIso: Date, endDateIso: Date, timezone: string) => {
    if (!startDateIso || !endDateIso) return `${moment().format('h:mm a')} - ${moment().format('h:mm a')}`;

    const startTime = moment(startDateIso).tz(timezone).format('h:mm a');
    const endTime = moment(endDateIso).tz(timezone).format('h:mm a');
    return `${startTime} - ${endTime}`;
};

export const formatDateOnGetCareMenu = (startDateIso: Date, endDateIso: Date, timezone: string) => {
    if (!startDateIso || !endDateIso)
        return `${moment().format('ddd DD MMM YYYY, h:mm a')} to ${moment().format('h:mm a')}`;

    const startDate = moment(startDateIso).tz(timezone);
    const endDate = moment(endDateIso).tz(timezone);
    return `${startDate.format('ddd DD MMM YYYY, h:mm a')} to ${endDate.format('h:mm a')}`;
};

export const formatVisitSummaryReportTitle = (startDateIso: string, endDateIso: string, location: LocationTimezone) => {
    if (!startDateIso || !endDateIso)
        return `${moment().format('ddd, DD MMM YYYY h:mm a')} - ${moment().format('h:mm a')} (0 hour)`;

    const inOneDay = isSameDay(startDateIso, endDateIso, location);
    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    const startDate = moment(startDateIso).tz(timezone);
    const endDate = moment(endDateIso).tz(timezone);
    const diffHours = moment.duration(endDate.diff(startDate)).hours();
    return `${startDate.format('ddd, DD MMM YYYY h:mm a')} - ${
        inOneDay ? endDate.format('h:mm a') : endDate.format('ddd, DD MMM YYYY h:mm a')
    } (${diffHours} ${diffHours > 1 ? 'hours' : 'hour'})`;
};

export const isSameDay = (startDateIso: string, endDateIso: string, location: LocationTimezone) => {
    if (!startDateIso || !endDateIso) return true;

    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    const startDate = moment(startDateIso).tz(timezone);
    const endDate = moment(endDateIso).tz(timezone);
    return startDate.isSame(endDate, 'day');
};

export const getMomentDate = (date: Date | string, timezone: string) => {
    return moment(date).tz(timezone);
};

export const convertZoneDate = (date: Date | string, timezone: string) => {
    return moment.tz(moment(date).format('YYYY-MM-DD'), timezone);
};

export const getTodayMomentDate = (timezone: string) => {
    return moment().tz(timezone);
};

export const getTimezoneCode = (location: LocationTimezone) => {
    const user = localStorageGet(LS_USER_INFO) as UserInfo;
    const timezone = getTimezone(location, user.timezones);
    return moment().tz(timezone).format('z');
};

/**
 * return offset in milliseconds with destination timezone (CR's timezone)
 */
export function destOffsetInMillis(timezone = '', date = new Date()): number {
    const curTZ = date;
    curTZ.setHours(0, 0, 0, 0);
    const destTZ = convertToDestTZ(curTZ, timezone) || curTZ;
    return destTZ.getTime() - curTZ.getTime();
}

/**
 * Convert local date timezone to CR's timezone
 * @param date in local timezone
 */
export function convertToDestTZ(date: Date | undefined, timezone = '') {
    if (!date) return;
    const destTimeZone = timezone || TIMEZONES[APP_COUNTRY];
    return convertTZ(date, destTimeZone);
}

// Convert a date to different timezone
// https://stackoverflow.com/a/54127122/1158325
export function convertTZ(date: Date, tzString: string) {
    // console.log(
    //   "🚀 ~ file: DateUtils.tsx ~ line 142 ~ convertTZ ~ tzString",
    //   tzString
    // );
    // console.log("🚀 ~ file: DateUtils.tsx ~ line 142 ~ convertTZ ~ date", date);
    return new Date(
        date.toLocaleString('en-US', {
            timeZone: tzString ?? 'Australia/Melbourne',
        }),
    );
}

//TODO: fix later when timezone changes are implemented
// Return new date with destination timezone offset
export function newDate() {
    return new Date();
    //return new Date(new Date().getTime() + destOffsetInMillis());
}
