import React from 'react';
import BaseElem from '../BaseElem';
import '../Common.css';
import {UNDEF} from '../Constants';
import {dateToAMPM, formatDate} from '../Util/DateUtils';
import {clamp, positionVerticallyWithAnchor, setDocumentScrollEnabled} from '../Util/Util';
import {PortalDiv} from './CommonComponents';
import './InputField.css';

type InputFieldProps = {
    className?: string;
    inpClassName?: string;
    placeholder?: string;
    type: string; // types: 'date', 'time', 'text', 'number'
    date?: Date; // for type = 'date' & 'time'
    text?: string; // for type = 'text'
    hasError?: boolean; // show soft red color when true
    isBold?: boolean; // make the font bold
    disabled?: boolean; // disable the text field

    onTextChange?: Function; // input change callback
    onKeyPress?: Function; // keypress callback
    onFocus?: Function; // on gain focus callback
    onBlur?: Function; // on lose focus callback
    hideFocusBar?: boolean; // don't show green bar at bottom

    min?: number; // Minimum value for type = number
    max?: number; // Maximum value for type = number

    readOnly?: boolean; // readonly input field
    popupDisabled?: boolean; // don't show the popup

    autoComplete?: string;
    noAutoSelect?: boolean; // don't auto select text on focus
    width?: string; // when specified disable dynamic width
    maxWidth?: string;
    inlineExpand?: boolean; // expand the props.children below instead of dropdown popup
    manualBlur?: boolean; // means can only lose focus by manually calling component.blur()

    popupWidth?: string;
    popupHeight?: string; //fixed height i.e. "100px"
    popupMaxWidth?: string; //max width "300px"
    popupMaxHeight?: string; //max height "300px"
    popupAlignTop?: boolean;

    // to disable blur on enter key press
    blurOnEnterKey?: boolean;
    blurOnTabKey?: boolean;

    hidden?: boolean; // when true, hide the input field

    // Multi-line properties
    multiLine?: boolean;
    lineHeight?: number;
    maxLines?: number;

    // Floating label
    label?: string;

    // disable background and size animation
    dontAnimate?: boolean;
    children?: React.ReactNode;
};

type InputFieldState = {
    _isOpen: boolean;
};

export class InputField extends BaseElem<InputFieldProps, InputFieldState> {
    _wasScrollEnabled = false;
    _bindKeyPress: any;
    _hasFocus = false;
    _debounceActive = false;
    _isChanged = false;
    _value = '';

    _bindOnChange = this._onChange.bind(this);

    constructor(props: InputFieldProps) {
        super(props);
        this.state = {
            _isOpen: false,
        };
    }

    // Setup event listeners
    _enableListeners(isEnabled = false) {
        const self = this,
            doc = window.document;

        if (isEnabled && !self._bindKeyPress) {
            self._bindKeyPress = self._onKeyPress.bind(self);
        }

        const listener = isEnabled ? 'addEventListener' : 'removeEventListener';
        doc[listener]('keydown', self._bindKeyPress);

        if (self.props.children) {
            const wasScrollEnabled = self._wasScrollEnabled;
            if (isEnabled && !wasScrollEnabled) {
                self._wasScrollEnabled = setDocumentScrollEnabled(false);
            } else if (wasScrollEnabled) {
                setDocumentScrollEnabled(true);
                self._wasScrollEnabled = false;
            }
        }
    }

    componentWillUnmount() {
        this._enableListeners(false);
    }

    componentDidMount() {
        this.componentDidUpdate(this.props);
    }

    componentDidUpdate(_prevProps: InputFieldProps) {
        const self = this,
            props = self.props,
            type = props.type;
        if (!self._hasFocus) {
            if (type === 'date' || type === 'time') {
                self.updateValue(props.date);
            } else if (type === 'text' || type === 'number') {
                self.updateValue(props.text);
            }
        }
    }

    focus() {
        (this.refs.input as any).focus();
    }

    updateValue(val?: any) {
        const self = this,
            props = self.props,
            value = self._getValue(val);
        self._value = value;
        const inputElem = self.refs.input as HTMLInputElement;
        if (inputElem) {
            (self.refs.input as HTMLInputElement).value = value;
            (self.refs.label as HTMLElement).textContent =
                (value || props.placeholder || '') + (props.multiLine && value.endsWith('\n') ? 'x' : '');
            self._updateSize(true);
        }
    }

    getText() {
        return (this.refs.input as HTMLInputElement).value;
    }

    _updateSize(animate = false) {
        const self = this;
        const refs = self.refs,
            props = self.props,
            fixedWidth = !!props.width,
            isMultiline = !!props.multiLine,
            inpElem = refs.input as any,
            label = refs.label as HTMLElement,
            px = 'px';

        const inpStyle = inpElem.style;
        if (!fixedWidth) {
            inpStyle.transition = animate && !props.dontAnimate ? '' : 'unset';
            inpStyle.width = label.offsetWidth + px;
        } else if (isMultiline) {
            const lineHeight = props.lineHeight || 22;
            const rows = Math.ceil(label.offsetHeight / lineHeight);
            inpElem.rows = clamp(rows, 1, props.maxLines || 5);
        }
    }

    _getValue(val: any): string {
        const type = this.props.type;
        if (type === 'date') {
            return formatDate(val); //.substr(4);
        } else if (type === 'time') {
            return dateToAMPM(val);
        } else {
            // text
            return (val || '') as string;
        }
    }

    _onChange(e: any) {
        const self = this,
            target = e.currentTarget,
            value = target.value;
        (self.refs.label as HTMLElement).textContent =
            value + (self.props.multiLine && value.endsWith('\n') ? 'x' : '');
        self._updateSize();
        self._isChanged = true;
        self._debounceActive = true;
        self._value = target.value;

        self.debounce(() => {
            if (!self._debounceActive) return;
            self._debounceActive = false;
            self._invokeChange(target.value);
        }, 200);
    }

    _invokeChange(value: string) {
        const fn = this.props.onTextChange;
        fn && fn(this, value);
    }

    _onFocus(_e: any) {
        const self = this,
            props = self.props;
        if (self._hasFocus || self.state._isOpen) {
            self._hasFocus = true;
            return;
        }
        self._hasFocus = true;
        self._isChanged = false;
        self.setState({_isOpen: true});
        const fn = props.onFocus;
        fn && fn(self);

        //const anchor = e.currentTarget;

        // Need to skip a frame
        self.async(() => {
            self._enableListeners(true);
            self.positionPopup();
        });

        !props.readOnly && !props.noAutoSelect && self.async(() => (self.refs.input as HTMLInputElement).select(), 50);
    }

    positionPopup() {
        const self = this,
            props = self.props;
        const anchor = self.refs.input as any;
        if (anchor && self.props.children && !props.popupDisabled) {
            const popup = self.refs.popup as HTMLElement;
            if (!popup) return;
            const alignWithTop = props.popupAlignTop;
            const yOffset = alignWithTop ? 0 : 4;
            self.async(() =>
                positionVerticallyWithAnchor(popup, anchor.getBoundingClientRect(), 0, yOffset, alignWithTop),
            );
        }
    }

    _onBlur(_e: any) {
        const self = this,
            props = self.props;
        self._hasFocus = false;
        if (props.popupDisabled || (!props.children && !props.manualBlur)) {
            self.blur();
        }
    }

    blur() {
        const self = this;
        self._hasFocus = false;

        const input = self.refs.input as any;
        self._debounceActive && self._invokeChange(input.value);
        self._debounceActive = false;

        input.blur();

        self.setState({
            _isOpen: false,
        });

        self._enableListeners(false);

        self.props.onBlur && self.props.onBlur(self);

        const popup = self.refs.popup as any;
        if (popup) {
            const popupStyle = popup.style;
            popupStyle.opacity = 0;
            popupStyle.transform = '';
        }
    }

    _onKeyPress(e: any) {
        const self = this,
            props = self.props,
            key = e.key;

        key === 'Escape' && self.blur();

        if (
            (props.blurOnTabKey !== false && key === 'Tab') ||
            (!props.multiLine && props.blurOnEnterKey && key === 'Enter')
        ) {
            self.blur();
        }

        const fn = props.onKeyPress;
        fn && fn(self, key, e);
    }

    clearValue() {
        (this.refs.input as any).value = '';
    }

    _getPopupWidth(widthFromProps: any) {
        if (!widthFromProps) return UNDEF;
        if (widthFromProps === '100%') {
            const inp = this.refs.input as any;
            return !inp ? UNDEF : inp.offsetWidth + 'px';
        }
        return widthFromProps;
    }

    render() {
        const self = this,
            isPopupOpen = self.state._isOpen,
            props = self.props;
        const hasFocus = self._hasFocus;
        const label = props.label;
        const placeholderTxt = props.placeholder;
        const placeholder = hasFocus ? props.placeholder : label ? '' : placeholderTxt;
        const isLabelDocked = hasFocus || !!props.text;

        return (
            <div
                className={`input-field ${props.className || ''}${props.hidden ? ' gone' : ''}`}
                data-open={isPopupOpen ? '' : UNDEF}
                has-error={self.props.hasError && !hasFocus ? '' : UNDEF}
                style={{width: props.width}}>
                {/* This is to compute size of the text field */}
                <div
                    ref="label"
                    className="if-input if-measure-label"
                    style={{
                        margin: 0,
                        position: props.multiLine ? 'absolute' : 'fixed',
                        marginLeft: props.multiLine ? '8px' : UNDEF,
                        marginRight: props.multiLine ? '8px' : UNDEF,
                        maxHeight: (props.lineHeight || 22) * (props.maxLines || 30) + 'px', //22 * 5
                        overflowY: 'auto',
                        pointerEvents: 'none',
                        visibility: 'hidden',
                        lineHeight: props.lineHeight ? props.lineHeight + 'px' : 'inherit',
                        fontWeight: props.isBold ? 'bold' : 'inherit',
                    }}></div>

                {label && <div className={'if-lbl-float' + (isLabelDocked ? ' if-docked' : '')}>{props.label}</div>}

                {(() => {
                    if (props.multiLine) {
                        return (
                            <textarea
                                ref="input"
                                className={`if-input if-input-field if-type-${self.props.type} ${
                                    props.inpClassName || ''
                                }`}
                                style={{
                                    display: 'block',
                                    lineHeight: 'inherit',
                                    fontWeight: props.isBold ? 'bold' : 'inherit',
                                    width: props.width,
                                    maxWidth: props.maxWidth,
                                    boxSizing: props.width ? 'border-box' : UNDEF,
                                }}
                                has-focus={isPopupOpen ? '' : UNDEF}
                                onChange={self._bindOnChange}
                                onFocus={e => self._onFocus(e)}
                                onBlur={e => self._onBlur(e)}
                                readOnly={props.readOnly}
                                disabled={props.disabled}
                                rows={1}
                                placeholder={placeholder}
                            />
                        );
                    } else {
                        return (
                            <input
                                ref="input"
                                className={`if-input if-input-field if-type-${props.type} ${props.inpClassName || ''}`}
                                style={{
                                    fontWeight: props.isBold ? 'bold' : 'inherit',
                                    width: props.width,
                                    maxWidth: props.maxWidth,
                                    boxSizing: props.width ? 'border-box' : UNDEF,
                                }}
                                autoComplete={props.autoComplete || 's'}
                                type={props.type === 'number' ? 'number' : 'text'}
                                min={props.min}
                                max={props.max}
                                has-focus={isPopupOpen ? '' : UNDEF}
                                onChange={self._bindOnChange}
                                onFocus={e => self._onFocus(e)}
                                onBlur={e => self._onBlur(e)}
                                readOnly={props.readOnly}
                                disabled={props.disabled}
                                placeholder={placeholder}
                            />
                        );
                    }
                })()}

                <div className="if-bottom-bar"></div>

                {isPopupOpen && self.props.children && !self.props.popupDisabled && (
                    <PortalDiv>
                        <div className="if-fixed-overlay" onClick={() => self.blur()}>
                            <div
                                ref="popup"
                                onClick={e => self.eatClick(e)}
                                className={`if-popup if-popup-${self.props.type}`}
                                style={{
                                    width: self._getPopupWidth(props.popupWidth),
                                    height: props.popupHeight,
                                    maxWidth: props.popupMaxWidth,
                                    maxHeight: props.popupMaxHeight,
                                }}
                                has-focus={isPopupOpen ? '' : UNDEF}>
                                {self.props.children}
                            </div>
                        </div>
                    </PortalDiv>
                )}
            </div>
        );
    }
}
