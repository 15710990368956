import * as React from 'react';
import {UNDEF} from '../Constants';
//import { debounce } from "../Util/Util";

//TODO: add documentation later

type VListProps = {
    documentScroll?: boolean;
    parentScroll?: boolean;
    childrenHeight?: number;
    itemHeight: number;
    items: any[];
    selectedItem?: any;
    itemAtIndex: Function;
    offscreenItems?: number;
    style?: any;
    className?: string;

    parentScrollCont?: any;

    // experiment: rendering vanilla js elements
    ///vanilla?: boolean;

    // for change comparison
    itemIdProperty?: string;
    children?: React.ReactNode;
};
type VListState = {
    rCount: number;
};

export class VList extends React.Component<VListProps, VListState> {
    _itemCont: any;
    _bindOnScroll: any;
    _items: any[] = [];
    _indexes: any = {};
    _style: any;
    ///_template: any;

    constructor(props: VListProps) {
        super(props);
        this.state = {
            rCount: 0,
        };
        this._itemCont = React.createRef();
    }

    scrollCont() {
        return (this.props.parentScrollCont || this._itemCont.current) as HTMLElement;
    }
    vListCont() {
        return this._itemCont.current as HTMLElement;
    }

    _enableListeners(isEnabled: boolean) {
        const self = this,
            scrollContainer = self.props.documentScroll ? window : self.scrollCont();
        const listener = isEnabled ? 'addEventListener' : 'removeEventListener';
        const bindedScroll = self._bindOnScroll || self._onScroll.bind(self);
        self._bindOnScroll = bindedScroll;
        scrollContainer && scrollContainer[listener]('scroll', bindedScroll);
    }

    cleanup() {
        const self = this;
        ///self.props.vanilla && self._items.map(r => r.remove());
        self._items = [];
        self._indexes = {};
        self._style = UNDEF;
        //const filler = self.refs.filler as any;
        //filler && (filler.style.height = 0);
        //console.log('cleanup')
    }

    reload() {
        const self = this;
        self.cleanup();
        self._onScroll();
    }

    shouldComponentUpdate(nextProps: VListProps, nextState: VListState) {
        const self = this,
            state = self.state,
            props = self.props;
        if (state.rCount !== nextState.rCount) {
            return true;
        }

        const prevItems = props.items;
        const newItems = nextProps.items;

        const update = () => {
            self.cleanup();
            self._udpateScrollHeight(nextProps, newItems);
            return true;
        };

        if (
            props.selectedItem !== nextProps.selectedItem ||
            !prevItems ||
            !newItems ||
            prevItems.length !== newItems.length
        ) {
            return update();
        }

        const idProp = nextProps.itemIdProperty;
        for (let k = newItems.length; k--; ) {
            const v1 = newItems[k],
                v2 = prevItems[k];
            if (!idProp ? v1 !== v2 : v1[idProp] !== v2[idProp]) {
                return update();
            }
        }

        return false;
    }

    _udpateScrollHeight(props: VListProps, items: any[] = []) {
        if (!props.documentScroll) {
            const filler = this.refs.filler as any;
            const itemHeight = props.itemHeight,
                count = items.length;
            const scrollHeight = itemHeight * count; // - (props.childrenHeight || 0);
            filler && (filler.style.minHeight = Math.max(0, scrollHeight) + 'px');
        }
    }

    componentDidMount() {
        const self = this,
            props = self.props;
        ///self._template = props.vanilla && document.createElement('template');

        self._enableListeners(true);
        self._udpateScrollHeight(props, props.items);
        self._computeItems();
        // trigger initial render
        self.setState({rCount: this.state.rCount + 1});
    }

    componentWillUnmount() {
        this._enableListeners(false);
        ///this._template = UNDEF;
        this.cleanup();
    }

    _onScroll() {
        const self = this;
        ///self.props.vanilla ?
        ///self._computeItems() :
        self.setState({rCount: self.state.rCount + 1});
    }

    // Compute items to render
    _computeItems() {
        const self = this,
            props = self.props;
        const isDocScroll = props.documentScroll;
        const isParentScroll = props.parentScroll;
        const itemsContainer = self.scrollCont();
        ///const template = self._template;

        if (!itemsContainer) {
            return;
        }

        const itemHeight = props.itemHeight,
            count = props.items.length;
        const visibleHeight = isDocScroll ? window.innerHeight : itemsContainer.offsetHeight;
        const scrollHeight = itemHeight * count + (props.childrenHeight || 0);

        self._style = self._style || {
            ...props.style,
            position: 'relative',
            overflowY: isDocScroll || isParentScroll ? UNDEF : 'auto',
            height: isDocScroll || isParentScroll ? scrollHeight : UNDEF,
        };

        if (!visibleHeight) return false;

        const offScreenItems = props.offscreenItems || 4;

        let scrollY;
        if (isDocScroll) {
            let startY = itemsContainer.getBoundingClientRect().top;
            startY += isDocScroll ? window.scrollY : itemsContainer.scrollTop;
            scrollY = Math.max(0, window.scrollY - startY);
        } else {
            const parentOffset = isParentScroll ? (self.vListCont() || ({} as any)).offsetTop || 0 : 0;
            scrollY = Math.max(0, itemsContainer.scrollTop - parentOffset);
        }

        const sIdx = Math.max(0, Math.floor(scrollY / itemHeight) - offScreenItems);
        const eIdx = Math.min(count, Math.ceil((scrollY + visibleHeight) / itemHeight) + offScreenItems);

        const models = props.items;
        const items = [];
        const indexes: any = {};

        const currentIndexes = Object.keys(self._indexes);
        let reusables: any[] = currentIndexes
            .filter((idx: any) => idx < sIdx || idx >= eIdx)
            .map((idx: any) => self._indexes[idx]);

        for (let k = sIdx; k < eIdx && k < count; k++) {
            let item = self._indexes[k];
            const reusable = !item && reusables.pop();

            /*if(props.vanilla) {
        if(!item) {
          const html = props.itemAtIndex(reusable, models[k], k, count, 'transform: translate3d(0, ' + ((props.childrenHeight || 0) + k * itemHeight) + 'px, 0)');
          if(html !== reusable) {
            template.innerHTML = html;
            item = template.content.firstElementChild;
            itemsContainer.appendChild(item);
          } else {
            item = reusable;
          }
          item.idx = k;
        }
      }
      else {*/
            item =
                item ||
                props.itemAtIndex(reusable, models[k], k, count, {
                    position: 'absolute',
                    willChange: 'transform',
                    transform: 'translate3d(0, ' + ((props.childrenHeight || 0) + k * itemHeight) + 'px, 0)',
                });
            ///}

            indexes[k] = item;
            items.push(item);
        }

        /*if(props.vanilla) {
      for(let k = reusables.length; k--;) {
        const r = reusables[k];
        r.style.transform = 'translate3d(0, -10000px, 0)';
        indexes[r.idx*-10000] = r;
        items.push(r);
      }
    }*/

        self._items = items;
        self._indexes = indexes;
    }

    render() {
        const self = this,
            props = self.props;
        self._computeItems();
        //console.log('render ', self._style)
        return (
            <div ref={self._itemCont} style={self._style} className={props.className}>
                {props.children}
                {/*!props.vanilla &&*/ self._items}

                {/* Space filler for container scroll */}
                <div ref="filler" />
            </div>
        );
    }
}
