import axios, {AxiosResponse} from 'axios';
import Cookies from 'js-cookie';
import {ACCESS_TOKEN, MSG_SET_LOADING_STYLE, MSG_SHOW_SNACKBAR, SHOW_LOADING, UNDEF} from './Constants';
import {getCenterOf, localStorageGet, localStorageSave, postMessage} from './Util/Util';

export function getAccessToken() {
    return Cookies.get(ACCESS_TOKEN) || localStorageGet(ACCESS_TOKEN);
}

export function GET(url: string, headers?: any) {
    const path = url[0] === '/' || url.startsWith('http') ? url : process.env.REACT_APP_API_URL + '/' + url;
    const noCacheHeaders = {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
    };
    const reqHeaders = {
        ...noCacheHeaders,
        ...(headers || {}),
        Authorization: `Bearer ${getAccessToken()}`,
    };
    return axios.get(path, {withCredentials: true, headers: reqHeaders});
}
export function POST(url: string, params: any, headers?: any) {
    const path = url[0] === '/' || url.startsWith('http') ? url : process.env.REACT_APP_API_URL + '/' + url;
    const reqHeaders = headers || {Authorization: `Bearer ${getAccessToken()}`};
    return axios.post(path, params, {
        withCredentials: true,
        headers: reqHeaders,
    });
}
export function PUT(url: string, data: any, headers?: any) {
    const path = url[0] === '/' || url.startsWith('http') ? url : process.env.REACT_APP_API_URL + '/' + url;
    const reqHeaders = headers || {Authorization: `Bearer ${getAccessToken()}`};
    return axios.put(path, data, {headers: reqHeaders});
}
export function FB_GET(url: string) {
    axios
        .get(url)
        .then(function (response) {
            // handle success
            console.log(response);
            return response;
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
        .then(function () {
            // always executed
        });
}

export type ResponseCallback = (response?: AxiosResponse<any>, error?: any) => void;

export function showLoading(show = true, style: any = null) {
    postMessage(SHOW_LOADING, show ? 1 : -1, 1);
    style !== null && postMessage(MSG_SET_LOADING_STYLE, style);
    return true;
}

export function showSnackbar(
    msg: string,
    variant: string,
    durationMilli?: number | undefined,
    actionTitle?: string,
    onAction?: any,
) {
    postMessage(MSG_SHOW_SNACKBAR, {
        key: new Date().getTime(),
        variant: variant,
        message: msg,
        duration: durationMilli,
        actionTitle: actionTitle,
        onAction: onAction,
    });
}

export default class Api {
    private static call(
        method: string,
        url: string,
        params?: any,
        respCallback?: ResponseCallback,
        showLoader: any = true,
        showErrorSnackbar = true,
        showRetry = false,
        headers?: any,
    ) {
        if (showLoader) {
            if (showLoader instanceof HTMLElement) {
                //position loading indicator in center of html element
                const center = getCenterOf(showLoader);
                postMessage(MSG_SET_LOADING_STYLE, {
                    position: 'absolute',
                    left: center.x - 30,
                    top: center.y - 31,
                });
            } else {
                postMessage(MSG_SET_LOADING_STYLE, {});
            }
            showLoading();
        }

        const request =
            method === 'GET'
                ? GET(url, headers)
                : method === 'POST'
                ? POST(url, params, headers)
                : PUT(url, params, headers);
        request.then(
            res => {
                showLoader && showLoading(false);
                respCallback && respCallback(res);

                const data = res && res.data;
                const success = data && data.success;
                const message = data && data.message;
                if (success === false && showErrorSnackbar) {
                    const actionBtnTxt = showRetry ? 'Retry' : UNDEF;
                    const callback = !actionBtnTxt
                        ? UNDEF
                        : () => {
                              Api.call(method, url, params, respCallback, showLoader, showErrorSnackbar, showRetry);
                          };
                    showSnackbar(message, 'warning', UNDEF, actionBtnTxt, callback);
                }
            },
            err => {
                showLoader && showLoading(false);
                respCallback && respCallback(UNDEF, err);

                if (showErrorSnackbar) {
                    const resp = err.response;

                    // Don't show network errors (have another snackabr for that)
                    if (!resp) return;

                    const data = resp && resp.data;
                    const error = (data && (data.error_description || data.message || data.error)) || err.message; // + '\n/' + url;

                    const isAuthProblem = resp && resp.status === 401;
                    const relog =
                        isAuthProblem &&
                        (() => {
                            //Cookies.remove('userid');
                            localStorageSave('user_info'); //delete user info object
                            window.location.reload();
                        });
                    const actionBtnTxt = isAuthProblem ? 'Re-Login' : showRetry ? 'Retry' : UNDEF;
                    const callback = !actionBtnTxt
                        ? UNDEF
                        : isAuthProblem
                        ? relog
                        : () => {
                              Api.call(method, url, params, respCallback, showLoader, showErrorSnackbar, showRetry);
                          };
                    showSnackbar(error, 'error', UNDEF, actionBtnTxt, callback);
                }
            },
        );
    }

    static GET(
        url: string,
        respCallback?: ResponseCallback,
        showLoading: any = true,
        showErrorSnackbar = true,
        showRetry = false,
        headers?: any,
    ) {
        Api.call('GET', url, UNDEF, respCallback, showLoading, showErrorSnackbar, showRetry, headers);
    }

    static POST(
        url: string,
        params: any,
        respCallback?: ResponseCallback,
        showLoading: any = true,
        showErrorSnackbar = true,
        showRetry = false,
        headers?: any,
    ) {
        Api.call('POST', url, params, respCallback, showLoading, showErrorSnackbar, showRetry, headers);
    }

    static PUT(
        url: string,
        data: any,
        respCallback?: ResponseCallback,
        showLoading: any = true,
        showErrorSnackbar = true,
        showRetry = false,
        headers?: any,
    ) {
        Api.call('PUT', url, data, respCallback, showLoading, showErrorSnackbar, showRetry, headers);
    }
}
